$theme-colors: (
  'primary': (
    'base': #00acea,
    'light': #40c4ff,
    'light-opacity': #ebf9ff,
  ),
  'primary2': (
    'base': #303859,
    'light': #2158cb,
  ),
  'secondary': (
    'base': #084575,
  ),
  'primary--disabled': (
    'base': #477ef1,
    'light': #477ef1,
    'dark': #477ef1,
  ),
  'background': (
    'base': #e0e0e0,
    'medium': #979797,
    'dark': #424242,
    'light': #f1f0f0,
  ),
  'button-secondary': (
    'base': #fa716a,
  ),
  'black': (
    'base': #000,
    'light': #000,
    'dark': #000,
  ),
  'white': (
    'base': #fff,
    'light': #fff,
    'dark': #fff,
  ),
  'red': (
    'base': red,
    'secondary': #d24826,
  ),
  'error': (
    'base': #d40000,
  ),
  'success': (
    'base': #4bb543,
    'primary': #41bb00,
    'secondary': #0fbc00,
  ),
  'warning': (
    'base': #ffc20e,
  ),
  'statement': (
    'base': #c1efff,
  ),
  'box-shadow': (
    'base': #999,
  ),
  'mui': (
    'base': rgba(0, 0, 0, 0.54),
    'border': #ddd,
  ),
  'blue': (
    'base': rgb(0, 17, 255),
  ),
  'green': (
    'base': rgb(0, 119, 0),
  ),
  'purple': (
    'base': rgb(132, 0, 255),
    'primary': #9e00f8,
  ),
  'orange': (
    'base': rgb(255, 155, 0),
  ),
  'clear': (
    'base': rgba(255, 255, 255, 0),
    'light': rgb(220, 220, 220),
  ),
  'gray': (
    'light': rgba(64, 196, 255, 0.1),
  ),
  'mui-input-selected': (
    'base': #3f51b5,
  ),
);

@function theme-color($key: 'primary', $variant: 'base') {
  $map: map-get($theme-colors, $key);
  @return map-get($map, $variant);
}
