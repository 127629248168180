@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

$border-color: #999999;
$background-color: #fff;
$text-color: #000;

.close-btn-container {
  position: absolute;
  top: 1em;
  right: 1em;
  .close-btn {
    &:hover {
      cursor: pointer;
    }
  }
}

.prescription-preview__image {
  width: 100%;
}

.prescription-preview__message {
  padding-bottom: 1em;
  border-bottom: 1px solid $border-color;
}

.MuiDialogActions-root {
  padding-bottom: 1em;

  button {
    text-transform: uppercase;
    @extend .button;
    @extend .button--primary;

    &.cancel-btn {
      @extend .button--secondary;
      border-color: $border-color;
      background-color: $background-color;
      color: $text-color;
    }
  }
}
