@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';

.pane--collapsed {
  .cal-res {
    display: none;
  }
}

.cal-res {
  @extend .custom-scrollbar;

  text-align: center;
  height: 12em;
  overflow-y: auto;

  @media (min-width: $screen-lg) {
    height: 20em;
    margin: 2em 0;
  }
}

.cal-res__title {
  color: theme-color('primary2');
  font-size: 0.9rem;
  margin: 0 0 1em;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;
  }
}

.cal-res__list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 1em;
  text-align: left;

  label {
    margin: 0;

    &.Mui-disabled {
      cursor: not-allowed;
    }
  }
}

.cal-res__item {
  > label {
    span {
      &:last-child {
        font-size: 0.8rem;

        @media (min-width: $screen-lg) {
          font-size: 1rem;
        }
      }
    }
  }
}

.cal-res__item--hidden {
  display: none;
}

.cal-res__more,
.cal-res__less {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  padding-top: 1em;
}
