@import '../../Styles/theme';
@import '../../Styles/buttons';

.appt-types {
  padding: 0 2em 1em;
}

.appt-types__table {
  border-spacing: 0 1em;

  td {
    padding-right: 1.5em;
    text-align: left;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding: 0;
    }
  }
}

.appt-types__name {
  margin: 0;
  width: 20em;
}

.appt-types__interval {
  margin: 0;

  > div {
    &.Mui-disabled {
      > div {
        cursor: not-allowed;
      }
    }

    > div {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }
}

.appt-types__actions {
  display: flex;
}

.appt-types__action {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  margin-right: 1em;
  padding: 0;
  outline: none;

  &:focus {
    border-color: theme-color('primary');
  }
}

.appt-types__action--move-down {
  transform: rotate(180deg);
}

.appt-types__modal-body {
  font-size: 1.1rem;
  font-weight: 600;
}

.appt-types__appt-type {
  font-weight: 700;
}

.appt-types__modal-button {
  @extend .button;
}

.appt-types__modal-button--cancel {
  background: none;
  border: none;
  color: theme-color('background', 'medium');
}

.appt-types__modal-button--confirm {
  @extend .button--primary;
}

.appt-types__add {
  align-items: center;
  background: none;
  border: 2px solid transparent;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  outline: none;
  padding-left: 0;

  &:disabled {
    @extend .button--disabled;

    background: none;
    border: none;
  }

  &:focus {
    border-color: theme-color('primary');
  }
}

.appt-types__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.appt-types__save {
  @extend .button;

  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
  color: theme-color('white');

  &:disabled {
    @extend .button--disabled;
  }
}
