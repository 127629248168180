@import '../../Styles/screens';

.login-header {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-header__logo-cont {
  width: 14em;

  img {
    width: 100%;
  }

  @media (min-width: $screen-lg) {
    width: 75%;
  }
}
