@import './theme';

.input-checkbox-cont {
  display: inline-block;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  padding: 2px 0 0 28px;
  position: relative;
  user-select: none;

  &.disabled {
    color: theme-color('background', 'medium');
    cursor: not-allowed;

    .checkbox {
      &:after {
        border-color: theme-color('background', 'medium');
      }
    }
  }

  &.checkbox-primary {
    color: theme-color('primary');

    &.disabled {
      color: theme-color('background', 'medium');
      cursor: not-allowed;

      .checkbox {
        border-color: theme-color('background', 'medium');

        &:after {
          border-color: theme-color('background', 'medium');
        }
      }
    }

    .checkbox {
      border-color: theme-color('primary');
    }
  }

  /* Hide the browser's default radio button */
  input[type='checkbox'] {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:focus {
      ~ .checkbox {
        border-color: theme-color('primary');
      }
    }

    &:checked {
      ~ .checkbox {
        &:after {
          content: '';
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  .checkbox {
    background-color: theme-color('white');
    border: 2px solid theme-color('background', 'medium');
    border-radius: 3px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;

    &:after {
      border: solid theme-color('primary');
      border-width: 0 3px 3px 0;
      height: 14px;
      left: 4px;
      position: absolute;
      width: 8px;
    }
  }
}
