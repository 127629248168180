@import '../../Styles/theme';
@import '../../Styles/screens';

.multi-modal {
  span {
    font-weight: bold;
    font-size: small;
  }
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 0px;
  }
  .MuiTypography-body1 {
    font-size: small;
  }
  .MuiDialog-paperWidthSm {
    max-width: 750px;
  }
  .title--text {
    padding: 10px;
    border-bottom: 1px solid black;
    height: 50px;
    margin-top: -35px;
  }
  .heading--text {
    margin-left: 40px;
    width: 200px;
  }
  .icon--position {
    margin-top: -28px;
  }
  .text--field--inputs {
    display: flex;
    flex-direction: row;
  }
  .text--inputs {
    padding: 10px;
  }
  .text--size {
    width: 170px;
  }
  .recipients--list {
    display: flex;
    align-items: center;
    width: 315px;
  }
  .recipient--box {
    width: 315px;
  }
  .message--box {
    width: 600px;
  }
  .submit--button {
    display: flex;
    align-items: flex-end;
    margin-top: 110px;
  }
  .search--button {
    display: flex;
    height: 40px;
    margin-top: 28px;
  }
  .phone--option {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  .button--group {
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: space-around;
    margin-top: 17px;

    .MuiButton-root {
      display: flex;
      min-width: 10px;
    }
  }
  .icon-button {
    display: flex;
    justify-content: flex-end;

    :hover {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .multi-modal {
    animation-duration: 0.5s;
    animation-name: modalSlideIntoView;
    .text--field--inputs {
      display: flex;
      flex-direction: column;
    }
    .title--text {
      height: 25px;
    }
    .MuiTypography-body1 {
      display: none;
    }
    .recipients--list {
      display: flex;
      flex-direction: column;
      width: 263px;
    }
    .button--group {
      display: flex;
      flex-direction: row;
      height: 50px;
      padding-right: 10px;
      .MuiButtonBase-root {
        transform: rotate(90deg);
        margin-left: 10px;
      }
    }
    .text--inputs {
      display: flex;
      flex-direction: column;
    }
    .text--size {
      width: 250px;
    }
    .recipient--box {
      width: 250px;
    }
    .two-way-message-input {
      display: flex;
    }
    .search--button {
      display: contents;

      .MuiButton-root {
        width: 250px;
        margin-left: 10px;
      }
    }
    .phone--option {
      display: flex;
      justify-content: center;
    }
  }
}
