@import '../../Styles/theme';
@import '../../Styles/shapes';

.Muical-queuetooltip-paper {
  border-radius: 8px !important;
}

.cal-queuetooltip__content {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.cal-queuetooltip__header {
  display: flex;
  justify-content: flex-end;
}

.cal-queuetooltip__action {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1em 0.5em;

  > span {
    text-align: center;
  }
}

.cal-queuetooltip__divider {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: 1.5em;
  margin-top: 0.8em;
}

.cal-queuetooltip__action--close {
  height: 2.1em;
  margin: 0.5em 0.5em;
}

.cal-queuetooltip__body {
  display: flex;
  padding: 1em 0 1em 2em;
}

.cal-queuetooltip__body-right {
  padding-left: 1em;

  h3 {
    color: theme-color('background', 'medium');
    font-size: 1.5rem;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.cal-queuetooltip__appt-color {
  @extend .shape-circle;
  background-color: theme-color('background');
  border: theme-color('background');
}
