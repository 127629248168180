@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

@media print {
  .prescription-upload__workspace-instructions,
  .prescription-upload__controls {
    visibility: hidden;
    height: 0;
  }
}

.tooltipText {
  width: 100%;
}

.tooltipImg {
  max-width: 100% !important;
  max-height: 100% !important;
}

.prescription-upload {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-bottom: 2em;
  &.error {
    .MuiPaper-root {
      border-color: red;
    }
  }
}

.prescription-upload--with-image {
  height: auto !important;
}

.prescription-upload__workspace {
  flex-basis: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    &:hover {
      cursor: pointer;
    }
    .dropzone-instructions {
      display: flex;
      flex-direction: column;
      text-align: center;
      & > * {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}

.prescription-upload__workspace-label {
  display: block;
  background: white;
  color: grey;
  font-size: 12px;
  transform: translate(1em, -0.5em);
  margin: 0;
  padding: 0 0.5em;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  .required {
    font-size: 1em;
    color: red;
  }
  flex-basis: 12px;
}

.prescription-upload__workspace-surface {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.prescription-upload__workspace-instructions {
  font-size: 0.8em;
}

.prescription-upload__controls {
  flex-basis: auto;
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  button {
    @extend .button;
    @extend .button--primary;
    margin-bottom: 1em;
    text-transform: uppercase;
  }
}

canvas.cropper-canvas {
  &.photo {
    position: relative;
    width: 100%;
  }
  &:not(.photo) {
    display: none;
  }
}
