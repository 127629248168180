@import '../../Styles/theme';
@import '../../Styles/buttons';

@media print {
  .dashboard__stellest-order-tracker {
    visibility: hidden;
    height: 0;
  }
}

.dashboard__stellest-order-tracker {
  .stellest-dashboard__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 1em 0;
    padding: 2em 0 1em 0;
    // border-bottom: 1px solid #e0e0e0;
    img {
      height: 60px;
    }
  }
  .red-highlight {
    color: red !important;
    font-weight: bold !important;
  }
  .MuiTableRow-root:nth-child(odd) {
    background-color: theme-color('background', 'base');
  }

  .customerButton--link {
    @extend .button--link;
    color: theme-color('blue');
    cursor: pointer;
    text-decoration: underline;
  }

  .customerDataButton {
    color: white;
    cursor: pointer;
    background-color: #00acea;
    border: none;
    border-radius: 5px;
    padding: 10px;
    width: 175px;
  }
  .error {
    padding: 1em 2em;
    h2 {
      color: red;
    }
  }
}
