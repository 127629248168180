@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

$mui-input-border-color: rgba(0, 0, 0, 0.23);

.stellest-form {
  @extend .custom-scrollbar;
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  z-index: 999;

  input[disabled] {
    background: theme-color('white');
    color: theme-color('background', 'medium');
  }

  .existing-order.MuiAccordion-root {
    .MuiFormLabel-asterisk.MuiInputLabel-asterisk,
    .required {
      display: none;
    }

    &.Mui-expanded {
      margin: 0;
    }
    &.Mui-expanded:before {
      opacity: 1;
    }

    .MuiAccordionSummary-root.order-header {
      padding: 0;
      .MuiIconButton-edgeEnd.MuiIconButton-root {
        margin-right: 0;
        position: relative;
        right: 2em;
        .MuiSvgIcon-root {
          font-size: 2em;
          fill: black;
        }
      }
      .MuiAccordionSummary-content {
        margin: 1em 4em;
        h2 {
          color: #025c82;
          font-weight: normal;
        }
      }
    }

    .MuiAccordionDetails-root.stellest-appointment-form-section {
      padding: 0;
      .stellest-appointment-form-section {
        margin: 0;
      }
    }
  }

  .stellest-appointment-form-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 4em;
    padding-top: 2em;

    .practice-details {
      display: none;
    }

    &.MuiAccordionDetails-root {
      padding-top: 0;
    }
    h2 {
      color: #025c82;
      font-weight: normal;
      margin: 0;
      margin-bottom: 2em;
      width: 100%;
      .required {
        float: right;
        font-size: 0.9rem;
        .astrisk {
          color: red;
          display: inline;
        }
      }
    }
    hr {
      width: 100%;
    }
  }
}

.MuiFormHelperText-root.Mui-error,
.Mui-focused .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: red;
}
