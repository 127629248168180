@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/theme';
@import '../../Styles/buttons';

.appointments-details {
  margin-top: 1em;
  border-top: 1px solid theme-color('background', 'medium');
  padding-top: 1em;
}

.appointments-details--header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;

  > div {
    display: flex;

    > button {
      @extend .button--filter;
      &[active='true'] {
        background-color: theme-color('statement', 'base');
      }
    }
  }
}

.appointments-details--table {
  border-collapse: separate;
  border: 1px solid theme-color('background', 'medium');
  border-radius: 25px;
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;

  > thead {
    > tr {
      > th {
        border-bottom: 1px solid theme-color('background', 'medium');
        border-right: 1px solid theme-color('background', 'medium');
        font-size: 0.8rem;
        padding: 0.8em;
        text-align: left;
        text-transform: uppercase;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  > tbody {
    border-top: 1px solid theme-color('background', 'medium');
    border-bottom: 1px solid theme-color('background', 'medium');
    > tr {
      > td {
        border-right: 1px solid theme-color('background', 'medium');
        border-bottom: 1px solid theme-color('background', 'medium');
        padding: 0.8em;
        text-align: left;

        &:first-child {
          text-align: center;
        }

        &:last-child {
          border-right: none;
        }
      }

      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }
}

.notifications__status {
  width: 5%;
}

.notifications__dot {
  @extend .shape-circle;

  border: none;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.notifications__dot-label {
  font-size: 0.5rem;
  padding-left: 0.8em;
  text-transform: uppercase;

  @media (min-width: $screen-xl) {
    font-size: 0.8rem;
  }
}

.notifications__no-notifications {
  cursor: initial !important;

  > td {
    font-weight: 700;
  }
}
