@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

.vac-main {
  @extend .custom-scrollbar;

  height: calc(var(--vh, 1vh) * 88);
  padding: 0 2em;
  width: auto;

  @media (min-width: $screen-lg) {
    height: auto;
    width: 650px;
  }
}

.vac-main__commands {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1em 0 1.5em;

  @media (min-width: $screen-lg) {
    padding: 1em 1.5em;
  }
}

.vac-main__title {
  color: theme-color('primary2');
  font-size: 1rem;
  margin: 0;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
  }
}

.vac-main__close {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
}

.vac-main__group {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    flex-direction: row;
    padding: 1em 1.5em;
  }
}

.vac-main__group--bt {
  border-top: 1px solid theme-color('mui');
  margin: 0 1.5em;
  padding: 1.5em 0;
}

.vac-main__list {
  width: 100%;
}

.vac-main__button {
  @extend .button;

  margin-bottom: 1em;
  padding-right: 2em;
  padding-left: 2em;

  &:disabled {
    @extend .button--disabled;
  }

  @media (min-width: $screen-lg) {
    margin: 0;
  }
}

.vac-main__input {
  width: 100%;

  @media (min-width: $screen-lg) {
    margin-bottom: 0 !important;
  }
}
