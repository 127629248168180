@import '../../Styles/theme';
@import '../../Styles/screens';

.conversation-template {
  font-size: 0.8em;
  padding: 15px 20px 0px;
  border-top: 1px solid theme-color('background', 'medium');
  background-color: theme-color('white', 'base');
  z-index: 8;
  box-shadow: 0px 5px 5px -3px theme-color('mui', 'border'),
    0px 8px 10px 1px theme-color('mui', 'border'),
    0px 3px 14px 2px theme-color('mui', 'border');

  &__container {
    display: flex;
    justify-content: space-between;

    &-divider {
      margin: 0 1em !important;
    }

    &-message {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      flex: 1 1;

      &-textarea-container {
        position: relative;
        flex-grow: 1;

        textarea {
          height: 100%;
          resize: none;
          border: 1px solid theme-color('mui', 'border');
          font-size: 1rem;
          font-family: inherit;
          font-weight: 400;
          padding: 5px;
          flex-grow: 1;
        }

        .textarea-icon {
          font-size: 1em !important;
          display: inline-block;
          position: absolute;
          bottom: 0.5em;
          right: 0.5em;
        }
      }

      &-characters {
        color: theme-color('background', 'dark');

        &.disabled {
          color: theme-color('background', 'medium');
        }
      }

      &-subtext {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1em;

        &-error {
          color: theme-color('red', 'secondary');
          margin-left: 1em;
        }

        &-fileinfo {
          .MuiSvgIcon-root {
            font-size: 0.8em !important;
            color: theme-color('red', 'secondary');
          }
        }
      }

      @media screen and (max-width: $screen-xl) {
        width: 100%;
        margin-left: 0;
        margin-top: 1em;
      }

      @media screen and (min-width: 1100px) and (max-width: $screen-lg) {
        margin-top: 1em;
      }
    }

    &-side-bar {
      flex: none;
      display: grid;
      grid-template-columns: [emojis] 1fr [attachments] 1fr [information] 1fr;
      grid-template-rows: [icons] 0.5fr [emptyspace] 1fr [button];

      .MuiSvgIcon-root {
        padding: 0 10px;
        position: relative;
        right: 0;
        z-index: 10;
      }

      &-icon {
        cursor: pointer;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .create-a-quote-btn {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 4;
        margin: 1em;
      }
    }

    .emoji--btn {
      font-size: 20px;
    }

    &-send {
      margin-bottom: 1em;

      &--button {
        vertical-align: top;
        height: 2.5em;
        margin-right: 20px;
        border: none;
        border-radius: 4px;
        width: 10.62em;

        &.active {
          background-color: theme-color('button-secondary', 'base');
          color: theme-color('white', 'base');
        }
      }

      @media screen and (max-width: $screen-xl) {
        text-align: right;
      }
    }

    @media screen and (max-width: $screen-xl) {
      flex-direction: column;
    }

    &-emoji {
      pointer-events: none;

      @media screen and (max-width: $screen-xl) {
        position: absolute;
        bottom: 0;
        right: 14.5em;
        margin-bottom: 1.7em;
      }
    }
  }

  .select-container {
    .select-label {
      color: theme-color('black', 'base');
    }

    .select-input {
      margin-top: 1em;
      width: 13em;

      @media screen and (max-width: $screen-xl) {
        width: 100%;
      }
    }

    .select-container-mobile--close {
      display: none;

      &.visible {
        @media only screen and (max-width: $screen-lg) {
          display: block;
          z-index: 2;
          background: white;
          text-align: right;
        }
      }
    }
  }
}

#menu-select-from-template {
  .MuiMenu-list {
    li {
      border-bottom: 1px solid theme-color('mui', 'border');

      &:hover {
        background-color: theme-color('primary', 'light-opacity') !important;
      }

      @media only screen and (max-width: $screen-lg) {
        border-bottom: none;
        display: flex;
        justify-content: center;

        :hover {
          font-weight: 500;
        }
      }
    }
  }

  .MuiList-padding {
    border: 1px solid theme-color('box-shadow', 'base');
    padding: 0 !important;

    @media only screen and (max-width: $screen-lg) {
      border: none;
      overflow-y: scroll;
    }
  }

  .MuiListItem-root.Mui-selected {
    background-color: theme-color('primary', 'light-opacity') !important;
  }
}
