.patient-component {
  width: 100%;
  section {
    display: flex;
    flex-direction: column;

    .input-group {
      display: flex;
      flex-direction: row;
      .primary-input {
        min-width: 50%;
        flex-basis: 0.5;
        display: flex;
        flex-direction: column;
      }
      .secondary-input {
        min-width: 50%;
        flex-basis: 0.5;
        display: flex;
        flex-direction: column;
        padding-left: 0 1em;
      }
    }

    .MuiTextField-root {
      margin-bottom: 1em;
    }
  }
}
