@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

$border-color: #999999;
$background-color: #fff;
$text-color: #000;

.create-quote-dialog {
  .MuiDialog-container {
    .MuiDialog-paper {
      height: 60%;
      @media (max-width: $screen-md) {
        height: 100%;
      }
      max-width: 100%;
      max-height: 100%;
      margin: 0em;
      padding: 0em;
    }
  }
}

.create-quote-dialog--title {
  &--container {
    display: flex;
    justify-content: space-between;

    &--modal-title {
      display: flex;
      margin: 0;
    }

    &--close-btn {
      display: flex;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.create-quote-dialog--content {
  background-color: theme-color('background', 'base');
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    margin: 0em;
    padding: 0.5em;
    height: 100%;
    @media (max-width: $screen-md) {
      height: 100%;
    }
    @media (min-width: $screen-lg) {
      margin: 2em;
      padding: 1em;
    }

    border-radius: 0;
    .input-container {
      flex: 1;
      margin-bottom: 1em;
      @media (min-width: $screen-md) {
        padding: 1em;
      }
    }
    textarea {
      border-radius: 0;
      background-color: theme-color('background', 'light');
      font: inherit;
      height: 100%;
    }
    p.instructions {
      // flex: 1;
      padding-bottom: 1em;
    }
  }
}

.MuiDialogActions-root {
  padding-bottom: 1em;

  button {
    text-transform: uppercase;
    @extend .button;
    @extend .button--primary;

    &.cancel-btn {
      @extend .button--secondary;
      border-color: $border-color;
      background-color: $background-color;
      color: $text-color;
    }
  }
}
