@import '../../Styles/theme';
@import '../../Styles/buttons';

.kpi-summary {
  min-height: 14rem;
  min-width: 14rem;
  border: 1px solid theme-color('background');
  border-radius: 0.25rem;
  box-shadow: 0 0 8px #999;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
}

.kpi-summary__value {
  font-size: 3rem;
  font-weight: 500;
}

.kpi-summary__description {
  font-size: 1.2rem;
  font-weight: 600;
}

input[type='button'],
.kpi-summary__details-btn {
  background-color: theme-color('white');
  border-radius: 0.3em;
  cursor: pointer;
  font-size: 0.8rem;
  outline: none;
  padding: 0.4em 0.4em;
  border: 1px solid theme-color('background');
  font-size: 1rem;
  display: block;
  width: auto;
  margin: 0 2rem;

  &:focus {
    border-color: theme-color('primary');
  }

  &:disabled {
    @extend .button--disabled;
  }

  &[active='true'] {
    background-color: theme-color('statement', 'base');
  }
}

.kpi-summary__icon {
  max-height: 5rem;
  object-fit: contain;
}
