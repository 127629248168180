@media print {
  body,
  html {
    height: 100% !important;
    overflow: visible !important;
  }

  .stellest-order-content {
    background-color: white;
    width: 100%;
    z-index: 999;

    position: absolute; //this is what allows the normal position of 'fixed' to end up printing multiple pages if needed while still ignoring layout file; prevents page breaks from being inserted though.
    display: inline;
    height: 100% !important;
    overflow: visible !important;

    h3 {
      color: #00acea;
      font-size: 1.25rem;
      font-weight: 300;
      margin: 0 3em 5px;
    }

    .navbar-print {
      height: 4em;
      align-items: center;
      background-color: theme-color('primary2', 'base');
      display: flex;
      justify-content: flex-start;

      .stellest-brand {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 2em;

        img.stellest-logo {
          margin-right: 1em;
          display: inline;
          height: 2.25em;
        }
      }

      .text {
        color: theme-color('black', 'base');
        border-color: white;
        margin-left: auto;
        margin-right: 2em;
      }
    }

    .body {
      border: solid 2px theme-color('box-shadow');
      margin: auto;
      max-width: 1520px;

      .stellest-appointment-form-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 4em;
      }

      .practice-details-view,
      .patient-details-view,
      .order-details-view,
      .optional-details-view {
        .fields {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 1em;
          .field {
            width: 28%;
            margin-right: 1em;
            .field-label {
              font-size: 0.8em;
              margin-bottom: 0.25em;
            }
          }
        }
      }

      .practice-details-view {
        .fields-row2 {
          justify-content: flex-start;
          .field {
            width: 32%;
            margin-right: 1em;
            .field-label {
              font-size: 0.8em;
              margin-bottom: 0.25em;
            }
          }
        }
      }

      .order-details-view {
        .fields-row2 {
          justify-content: flex-start;
          margin-top: 0.5em;
          .field {
            width: 32%;
            margin-right: 1em;
            .field-label {
              font-size: 0.8em;
              margin-bottom: 0.25em;
            }
          }
        }
      }

      .optional-details-view {
        margin-top: 1em;
        .optional-fields {
          justify-content: flex-start;
          .field {
            width: 46%;
            margin-right: 1em;
            .field-label {
              font-size: 0.8em;
              margin-bottom: 0.25em;
            }
          }
        }
      }

      .prescription-image {
        display: flex;
        flex-direction: column;
        margin: 0 4em;
        .field-value-img {
          img {
            max-width: 85vw;
            max-height: 20vh;
          }
        }
        .field-label {
          font-size: 0.8em;
          margin-bottom: 0.25em;
        }
      }

      .prescription-table {
        width: 100%;
        margin: 1em 4em 1em;

        .label-text {
          display: block;
          background: white;
          color: grey;
          font-size: 12px;
          transform: translate(1em, -0.5em);
          margin: 0;
          padding: 0 0.5em;
          position: absolute;
          z-index: 1;
        }
        .label {
          display: flex;
          position: relative;
          width: 85%;
          span {
            align-items: center;
            color: theme-color('box-shadow');
            border: 1px solid rgba(0, 0, 0, 0.23);
            border-right: none;
            display: flex;
            height: 3.8em;
            justify-content: flex-start;
            padding-left: 0.5em;
            text-transform: uppercase;
            width: calc(100% / 6);
            &:first-of-type {
              border-top-left-radius: 0.5em;
            }
            &:last-of-type {
              border-right: 1px solid theme-color('box-shadow');
              border-top-right-radius: 0.5em;
            }
          }
        }
        .fields {
          display: flex;
          flex-wrap: wrap;
          width: 85%;
          .field-row {
            display: flex;
            width: 100%;
            &:last-of-type {
              span {
                &:first-of-type {
                  border-bottom-left-radius: 0.5em;
                }
              }
              div {
                &:last-of-type {
                  border-bottom-right-radius: 0.5em;
                }
              }
            }
            span {
              align-items: center;
              color: theme-color('box-shadow');
              display: flex;
              border: 1px solid rgba(0, 0, 0, 0.23);
              border-top: 0;
              height: 3.8em;
              justify-content: center;
              width: calc(100% / 6);
            }
          }
        }
      }
    }
  }
}
