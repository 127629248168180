@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/shapes';

.cal-appt {
  align-items: center;
  color: theme-color('black');
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  padding: 0 10px;
}

.cal-appt__stellest-icon {
  @extend .shape-circle;
  background: transparent;
  border: none;
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  height: 25px;
  outline: none;
  width: 25px;
  padding: 0px;
}

.cal-appt__stellest-logo {
  height: 25px;
  width: 25px;
}

.cal-appt--current {
  p {
    color: theme-color('white');
  }
}

.cal-appt-cont {
  opacity: 1;
  pointer-events: initial;
  transition: opacity 1s;
}

.cal-appt-cont--hidden {
  opacity: 0.2;
  pointer-events: none;
  transition: opacity 1s;
}

.cal-appt__time {
  align-items: center;
  border-right: 1px solid theme-color('background', 'medium');
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  justify-content: center;
  margin: 0;
  padding: 0 2em;
}

.cal-appt__details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5em 1em;

  > p {
    font-size: 0.8rem;
    margin: 0;
  }
}

.cal-appt-form {
  display: flex;
  flex-direction: column;
}

.cal-appt-form__commands {
  display: flex;
  justify-content: space-between;
  padding: 1.5em 1.5em 1em 1em;
}

.cal-appt-form__close {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.cal-appt-form__save {
  @extend .button;
  @extend .button--primary;

  padding-right: 2em;
  padding-left: 2em;
}

.cal-appt-form__group {
  display: flex;
  padding: 1.5em 1.5em 0 1.5em;
}

.cal-appt-form__input {
  width: 100%;

  &:first-child {
    margin-right: 1em;
  }

  &:last-child {
    margin-left: 1em;
  }
}

.cal-appt__voucher {
  font-size: 1rem !important;
}
