@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';

.pw-reset {
  background-image: none;
  background-color: theme-color('white');
  height: 100%;
  position: relative;
  padding-bottom: 3em;

  @media (min-width: $screen-lg) {
    background-size: 67.5%;
    background-image: url('../../Assets/Images/Reset-Password-Screen-Art.jpg');
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 0 0 0 3em;
  }
}

.pw-reset__title {
  border-bottom: 1px solid theme-color('background', 'medium');
  color: #084575;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 1em 1.3em;

  @media (min-width: $screen-lg) {
    font-size: 2rem;
    margin-left: 0.5em;
    padding: 2em 0 1em 0;
    width: 75%;
  }
}

.pw-reset__statement {
  color: theme-color('secondary');
  font-size: 1.2rem;
  margin: 0;
  padding: 1em 1.7em 0;
}

.pw-reset__username {
  margin: 0;
  padding: 1.5em 2em 1.5em;
}

.pw-reset__form {
  padding: 0 2em;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.pw-reset__group {
  position: relative;
}

.pw-reset__input {
  width: 100%;

  > label {
    transform: translate(14px, 14px) scale(1);
  }
}

.pw-reset__show-pw {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 3px;
  top: 9px;

  &:focus {
    outline: none;
  }
}

.pw-reset__icon {
  color: theme-color('background', 'medium');
}

.pw-reset__icon--hide {
  display: none !important;
}

.pw-reset__error {
  color: theme-color('red');
  display: block;
  font-size: 0.6rem;
  margin-bottom: 1.5em;
  text-align: left;
  visibility: hidden;
}

.pw-reset__error--visible {
  visibility: visible;
}

.pw-reset__error--no-margin {
  margin-bottom: 0;
}

.pw-reset__rules {
  font-size: 0.6rem;
  list-style-type: none;
  margin: 0;
  padding: 0 0 1em;

  @media (min-width: $screen-lg) {
    font-size: 0.8rem;
  }
}

.pw-reset__rule-icon {
  font-size: 1rem !important;
  padding-right: 1em;
}

.pw-reset__rule-icon--hide {
  display: none !important;
}

.pw-reset__rule {
  color: theme-color('error');
  display: flex;
  padding-bottom: 0.5em;
}

.pw-reset__rule--valid {
  color: theme-color('success');
}

.pw-reset__success {
  color: theme-color('success');
  display: none;
  font-size: 0.8rem;
  margin-bottom: 1em;
  padding: 0 0 1em;
}

.pw-reset__success--visible {
  display: block;
}

.pw-reset__submit {
  @extend .button;
  @extend .button--primary;

  width: 100%;

  &:disabled {
    @extend .button--disabled;
  }
}

.pw-reset__illustration-cont {
  bottom: -4em;
  position: absolute;
  right: 0;
  transform: scaleX(-1);
  width: 40%;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.pw-reset__illustration {
  transform: scaleX(-1);
  width: 100%;
}
