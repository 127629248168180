@import '../../Styles/theme';
@import '../../Styles/buttons';

.appointment-dashboard {
  padding: 1em 2em;
}

.appointment-dashboard__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 1em 0;
  padding-bottom: 1.2em;
  border-bottom: 1px solid theme-color('background');
}

.appointment-dashboard__heading h2 {
  color: theme-color('primary');
  font-size: 2rem;
  margin: 0;
  padding-bottom: 0;
}

.appointment-dashboard__kpis {
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
}

.appointment-dashboard__placeholder {
  border: solid black 1px;
}

.appointment-dashboard__date-range {
  font-size: 0.9rem;
  font-weight: 600;
}

.appointment-dashboard__date-range .MuiFormControl-root {
  margin: 0 1rem;
  vertical-align: unset;
}
