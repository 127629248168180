@import "../../Styles/theme";
@import "../../Styles/shapes";
@import "../../Styles/screens";
@import "../../Styles/buttons";

.customers {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4em 5em auto;
  grid-template-areas:
    "submenu"
    "page"
    "footer";
}

.customers__submenu {
  align-items: center;
  border-bottom: 1px solid theme-color("background", "medium");
  display: flex;
  grid-area: submenu;
  justify-content: space-between;
  padding: 0 2em;
}

.customers__page {
  grid-area: page;
  padding: 0 5em;
}

.customers__title {
  margin: 0 1em 0 0;
}

.customers__search {
  width: 20em;
}

.customers__search-group {
  margin: 1em 0 1em 0;
}

.customers__search-input {
  width: 80%;
}

.customers__table {
  border: 1px solid theme-color("background", "medium");
  border-top: none;
  border-spacing: 0;
  margin-bottom: 1em;
  padding-top: 1em;
  width: 100%;

  > thead {
    > tr {
      > th {
        border-bottom: 1px solid theme-color("background", "medium");
        border-right: 1px solid theme-color("background", "medium");
        font-size: 0.8rem;
        padding: 0.8em;
        text-align: left;
        text-transform: uppercase;
        width: 15%;

        &:last-child {
          border-right: none;
          width: 3%;
        }
      }
    }
  }

  > tbody {
    > tr {
      > td {
        border-right: 1px solid theme-color("background", "medium");
        border-bottom: 1px solid theme-color("background", "medium");
        padding: 0.8em;
        text-align: left;

        &.customers__history-cell {
          text-align: center;
        }
      }

      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }
}

.customers__sort-col {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.customers__sort-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.customers__history-col {
  text-align: center;
}

.customers__name-link {
  background: none;
  border: none;
  color: theme-color("primary");
  cursor: pointer;
  outline: none;
}

.customers__history-cell {
  display: flex;
  justify-content: center;
}

.customers__history-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.customers__drawer {
  padding: 2em;
  width: 28em;
}

.customers__drawer-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.customers__drawer-title {
  color: theme-color("primary2");
  margin: 0;
}

.customers__close-drawer {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
}

.customers__close-drawer {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
}

.customers__drawer-content {
  padding: 1em 0 0;
}

.customers__profile-table {
  width: 93%;

  > tbody {
    > tr {
      > td {
        &:first-child {
          padding: 0.5em;
          text-align: right;
          width: 45%;

          &.customers__notes-cell {
            vertical-align: baseline;
          }

          &.customers__survey-cell {
            vertical-align: baseline;
          }

          &.customers__sms-one-way-warning {
            width: 100%;
            p {
              color: red;
              font-size: 0.8em;
              font-weight: 300;
              margin-top: -20px;
              text-align: left;
            }
          }
        }

        &:last-child {
          font-weight: 600;
          padding: 0.5em 0 0.5em 1em;
        }
      }
    }
  }
}

.customers__profile-notes {
  height: 8em;
  resize: none;
}

.customers__survey-answers {
  margin: 0;
  padding: 0 0 0 1em;

  > li {
    &::marker {
      font-weight: 400;
    }
  }
}

.customers__survey-question {
  font-size: 1rem;
  font-weight: 400 !important;
}

.customers__survey-answer {
  font-size: 1rem;
  font-weight: 600;
}

.customers__profile-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
}

.customers__close-btn {
  @extend .button;

  background: none;
  border-color: theme-color("background", "medium");
  width: 8em;
}

.customers__save-btn {
  @extend .button;
  @extend .button--secondary;

  margin-left: 1.5em;
  width: 8em;
}

.customers__panel-title {
  margin: 0;
}

.customers__panel-content {
  width: 100%;
}

.customers__panel-line {
  display: flex;
  margin-top: 1em;
}

.customers__panel-label {
  font-weight: 600;
  width: 5em;
}

.customers__panel-label {
  width: 8em;
}

.customers__rating {
  color: theme-color("primary2", "light");
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.customers__comments {
  margin: 0;
}

.customers__input {
  width: 100%;
}

.customers__panel-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
}

.customers__page-numbers {
  margin: 1em 1em;
  font-size: 1.2em;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.customers__page-numbers > li {
  margin-right: 1em;
  user-select: none;
  cursor: pointer;
}

.customers__page-numbers-selected {
  font-weight: bold;
  color: #00acea;
}
