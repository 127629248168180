@import '../../Styles/theme';
@import '../../Styles/buttons';

$bar-size: 30px;
$bar-color: #049ce5;
$bar-rounded: 3px;

.service-type-summary {
  max-height: 14rem;
  min-width: 25rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  box-shadow: 0 0 8px #999;
  text-align: center;
  padding: 1rem;
  overflow-y: auto;
}

.service-type-summary__title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.service-type-summary__typename {
  text-align: right;
  align-items: center;
  line-height: 1rem;
  font-size: 0.95rem;
  margin-top: -0.1rem;
}

.service-type-summary__container {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
}

.service-type-summary__container--left {
  height: 100%;
  width: 100%;
  min-width: 8rem;
}

.service-type-summary__container--center {
  width: 100em;
  flex-grow: 0 0 auto;
}

.service-type-summary__container--right {
  text-align: right;
  height: 100%;
  width: 100%;
  margin-left: 30px;

  &.active {
    button {
      background-color: theme-color('statement', 'base');
    }
  }
}

.service-type-summary__bars {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  flex-direction: column;
  border-bottom: none;
  background: none;
  .service-type-summary__bars--item {
    background-color: $bar-color;
    height: $bar-size;
    width: 0;
    min-width: 1%;
    align-self: flex-start;
    margin: auto 0 auto 0;
    border-radius: 0 $bar-rounded $bar-rounded 0;
    position: relative;
    &:hover {
      opacity: 0.7;
    }
    &::after {
      content: attr(data-name);
      top: initial;
      left: 100%;
      padding: 0 10px;
      display: inline-block;
      white-space: nowrap;
      position: absolute;
      transform: rotate(0deg);
      line-height: $bar-size;
    }
  }
}
