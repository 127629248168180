@import './Layout.scss';

.login__header {
  grid-area: header;

  @media (min-width: $screen-lg) {
    align-items: center;
    background-image: url('../../Assets/Images/Background-Texture.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
}

.login__content {
  grid-area: content;

  @media (min-width: $screen-lg) {
    padding-left: 3em;
  }
}

.login__footer {
  grid-area: footer;
}
