label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5em;
  text-align: left;
}

input,
select,
textarea {
  border: 2px solid theme-color('background', 'medium');
  border-radius: 0.5em;
  box-sizing: border-box;
  font-weight: 600;
  padding: 1em 0.8em;
  outline: none;
  width: 100%;

  &:focus {
    border-color: theme-color('primary');
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  font-weight: 600;
  padding-right: 4.5em;

  &:focus {
    ~ .select-cont__icon {
      border-left-color: theme-color('primary');
    }
  }
}

.select-cont {
  position: relative;
}

.select-cont__icon {
  border-left: 1px solid theme-color('background', 'medium');
  padding: 0.5em;
  position: absolute;
  right: 0;
  top: 0;
}

::placeholder {
  color: theme-color('background', 'medium');
  font-weight: 600;
  opacity: 1;
}

::-ms-input-placeholder {
  color: theme-color('background', 'medium');
  font-weight: 600;
}

.form-grp {
  margin-bottom: 1em;
}
