@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';
@import './StellestStyles';

.stellest-form {
  .stellest-order-content {
    visibility: hidden;
    height: 0;
    background-color: theme-color('white', 'base') !important;
  }

  .navbar {
    height: 4em;
  }

  .navbar {
    align-items: center;
    background-color: theme-color('primary2', 'base');
    display: flex;
    justify-content: flex-start;

    .back-btn {
      color: white;
      border-color: white;
      margin-left: auto;
      margin-right: 2em;
    }
    .eyebooknow-brand {
      border-right: 1px solid theme-color('background');
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 2em;
      img.eyebooknow-logo {
        padding-right: 2em;
        width: 15em;
      }
    }
    .stellest-brand {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 2em;
      img.stellest-logo {
        margin-right: 1em;
        display: inline;
        height: 2.5em;
      }
      h1 {
        padding: 0;
        font-size: 2em;
        font-weight: normal;
        display: inline;
        color: white;
        margin: 0;
      }
    }
  }

  .body {
    border: solid 3px theme-color('box-shadow');
    margin: auto;
    max-width: 1520px;
    .header {
      background-color: #3eadd5;
      padding: 1em 4em;
      letter-spacing: 0.1em;
      h2 {
        color: white;
        font-size: 2.5rem;
        font-weight: 300;
        margin: 0;
      }
      p {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    .add-patient-container {
      align-items: center;
      display: flex;
      color: white;
      background-color: theme-color('primary2', 'base');
      padding: 2em 2em;
      flex-direction: row;
      justify-content: flex-end;
      button {
        @extend .button;
        margin-left: 2em;
        width: 16em;
        &.stellest-form__cancel {
          border: 2px solid white;
          &:focus {
            border: 2px solid white;
          }
        }
        &.stellest-form_add-stellest {
          @extend .button--secondary;
        }
      }
      .message {
        font-size: 1.5em;
      }
    }
  }

  .success-overlay {
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    height: 100%;
    justify-content: center;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: inherit;
    .success-container {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
      background: white;
      padding: 30px 70px;
      position: relative;
      width: 510px;
      span {
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 26px;
      }
      h2 {
        color: #00acea;
        font-size: 3rem;
        font-weight: 300;
        margin: 20px 0;
      }
      p {
        font-size: 25px;
        font-weight: bold;
        line-height: 40px;
        &:last-of-type {
          font-size: 16px;
        }
      }
      button {
        @extend .button;
        background-color: #00acea;
        border: 1px solid #00acea;
        color: white;
        float: right;
        &.close-leave {
          color: black;
          background-color: white;
          border: 2px solid $mui-input-border-color;
          margin-left: 15px;
        }
      }
    }
  }
}
