@import '../../Styles/theme';
@import '../../Styles/screens';

.login-footer {
  align-items: center;
  background-color: theme-color('white');
  display: flex;
  font-size: 0.8rem;
  height: 100%;
  padding-left: 2.8em;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.login-footer__sponsor {
  font-size: 0.6rem;
}
