@import '../../Styles/theme';
.MuiDialog-root {
  .MuiDialog-container {
    .alert-dialog-title-close {
      position: absolute;
      top: 0;
      right: 0em;
      color: theme-color('black', 'base');
    }
    p#alert-dialog-description {
      color: theme-color('black', 'base');
    }
  }
}
