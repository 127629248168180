@import '../../Styles/theme';
@import '../../Styles/buttons';

.cust-storefront {
  display: flex;
  flex-direction: column;
  padding: 2em 0 2em 2em;
  width: 815px;
}

.cust-storefront__heading {
  color: theme-color('primary');
  font-size: 2rem;
  margin: 0 0 1em 0;
}

.cust-storefront__subheading {
  margin: 0 0 3em 0;
}

.cust-storefront__samples {
  margin-top: 4em;
  position: relative;
}

.cust-storefront__sample1-cont {
  width: 70%;
}

.cust-storefront__sample1-img {
  box-shadow: 0 0 8px theme-color('box-shadow');
  width: 100%;
}

.cust-storefront__sample2-cont {
  position: absolute;
  right: -123px;
  top: -25px;
  width: 45%;
}

.cust-storefront__sample2-img {
  box-shadow: 0 0 8px theme-color('box-shadow');
  width: 100%;
}

.cust-storefront__disclosure {
  bottom: 3px;
  color: theme-color('background', 'medium');
  font-size: 0.8rem;
  position: absolute;
  right: 0;
}

.cust-storefront__view-cont {
  display: flex;
  justify-content: flex-end;
}

.cust-storefront__view {
  @extend .button;

  background-color: theme-color('button-secondary');
  border: none;
  color: theme-color('white');
  margin-right: 1em;
  text-transform: uppercase;
  width: 20em;
}

.cust-storefront__copy {
  @extend .button;

  background-color: theme-color('button-secondary');
  border: none;
  color: theme-color('white');
  text-transform: uppercase;
  width: 20em;
}

.cust-storefront__google-statement {
  margin-top: 2em;
}

.cust-storefront__alert {
  background-color: theme-color('background') !important;

  > div {
    &:first-child {
      color: theme-color('black');
    }

    &:nth-child(2) {
      color: theme-color('black');
    }

    &:last-child {
      color: theme-color('black');
    }
  }
}

.cust-storefront__img {
  border: 1px solid theme-color('background');
  margin-top: 0.5em;
}

.cust-storefront__img-sm {
  width: 65%;
}

.cust-storefront__img-md {
  width: 85%;
}

.cust-storefront__img-lg {
  width: 100%;
}
