@import './Layout.scss';

.forgot-pw__header {
  grid-area: header;

  @media (min-width: $screen-lg) {
    align-items: center;
    background-image: url('../../Assets/Images/Background-Texture.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
}

.forgot-pw__content {
  background-color: theme-color('white');
  grid-area: content;
  position: relative;

  @media (min-width: $screen-lg) {
    background-size: 65%;
    background-image: url('../../Assets/Images/Reset-Password-Screen-Art.jpg');
    background-position: bottom right;
    background-repeat: no-repeat;
    padding-left: 3em;
  }
}

.forgot-pw__title {
  border-bottom: 1px solid #979797;
  color: #084575;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 1em 1.3em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 2rem;
    margin-left: 0.5em;
    padding: 2em 0 1em 0;
    width: 75%;
  }
}

.forgot-pw__statement {
  color: theme-color('secondary');
  margin: 0;
  padding: 1em 2em 2em 2em;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;
    padding-left: 1.6em;
  }
}

.forgot-pw__form {
  padding: 0 2em;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.forgot-pw__input {
  width: 100%;

  > label {
    transform: translate(14px, 14px) scale(1);
  }
}

.forgot-pw__error {
  color: theme-color('red');
  display: block;
  font-size: 0.6rem;
  margin-bottom: 1.5em;
  visibility: hidden;
}

.forgot-pw__error--visible {
  visibility: visible;
}

.forgot-pw__success {
  color: theme-color('success');
  display: none;
  font-size: 0.8rem;
  margin: 0;
}

.forgot-pw__success--visible {
  display: block;
}

.forgot-pw__submit {
  @extend .button;
  @extend .button--primary;

  width: 100%;

  &:disabled {
    @extend .button--disabled;
  }
}

.forgot-pw__illustration-cont {
  bottom: -4em;
  position: absolute;
  right: 0;
  transform: scaleX(-1);
  width: 55%;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.forgot-pw__illustration {
  transform: scaleX(-1);
  width: 100%;
}

.forgot-pw__footer {
  grid-area: footer;
}
