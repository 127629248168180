@import '../../Styles/theme';
@import '../../Styles/shapes';
@import '../../Styles/screens';
@import '../../Styles/buttons';

.notifications {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4em 5em auto;
  grid-template-areas:
    'menu'
    'submenu'
    'page'
    'footer';
}

.menu {
  grid-area: menu;
}

.submenu {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  grid-area: submenu;
  justify-content: space-between;
  padding: 0 1em 0 2em;
}

.page {
  grid-area: page;
  padding: 0 5em;
  overflow-x: auto;
  overflow-y: auto;
  margin: 0;
}
