@import '../../Styles/theme';

.emoji {
  &--holder {
    position: relative;
    color: theme-color('background', 'medium');
    display: flex;
    justify-content: center;
  }

  &--sheet {
    position: absolute;
    bottom: 100px;
    width: 180px;
    background-color: theme-color('background', 'light');
    padding: 20px;
    border-radius: 10px;
    box-shadow: -9px 10px 12px rgba(27, 55, 100, 0.2);
    left: 50%;
    margin-left: -120px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 15px);
    grid-template-rows: repeat(auto-fill, 15px);
    pointer-events: all;

    animation-duration: var(--app-animation-speed);
    animation-name: fadeIn;
    animation-fill-mode: forwards;

    &::before {
      content: '';
      position: absolute;
      right: 84px;
      border-top: 15px solid theme-color('background', 'light');
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      bottom: -15px;
    }
  }

  &--btn {
    cursor: pointer;
    font-size: 30px;
    user-select: none;
    pointer-events: all;

    &:hover {
      color: var(--app-color-primary);
    }
  }

  &--mark {
    cursor: pointer;
    font-size: 16px;
    min-width: 10px;
  }
}