@import './Layout.scss';

.terms-cond__title {
  border-bottom: 1px solid theme-color('background', 'medium');
  color: theme-color('primary2');
  font-size: 2rem;
  margin: 0 0 1em;
  padding-bottom: 1em;
  text-align: left;
}

.terms-cond__agreement {
  border-radius: 1em;
  border: 1px solid theme-color('background', 'medium');
  height: 28em;
  overflow-y: scroll;
  padding: 3em;
  resize: none;
  text-align: left;

  > ol {
    padding: 0;
  }
}

.terms-cond__cont {
  display: flex;
}

.terms-cond__iframe-cont {
  height: 28em;
  position: relative;
  width: 100%;

  > #terms-use-iframe {
    border-radius: 20px;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

.terms-cond__confirm {
  @extend .button;
  @extend .button--primary;
  margin-top: 1em;
  padding-right: 2em;
  padding-left: 2em;
}

.terms-cond__contentframe {
  height: 20em;
  padding: 0;
  position: relative;
  width: 95%;

  iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    border: none;
    html {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    }
  }
}

.terms-cond__link {
  background: none;
  border: none;
  color: theme-color('primary');
  cursor: pointer;
  outline: none;
  padding: 0;
}

.terms-cond__btn-modal {
  @extend .button;
  background-color: #fa716a !important;
  color: white;
  border: none !important;
  padding: 10px 25px !important;
  right: 25px;
  top: 265px;
  font-size: 14px !important;
}
