@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';

.login-temp {
  background-image: none;
  background-color: theme-color('white');
  height: 100%;
  position: relative;

  @media (min-width: $screen-lg) {
    background-size: 65%;
    background-image: url('../../Assets/Images/Login-Screen-Art.jpg');
    background-position: bottom right;
    background-repeat: no-repeat;
  }
}

.login-temp__title {
  border-bottom: 1px solid theme-color('background', 'medium');
  color: #084575;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0.8em 0 0.8em 1.3em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 2rem;
    margin-left: 0.5em;
    padding: 2em 0 1em 0;
    width: 75%;
  }
}

.login-temp__statement1 {
  color: theme-color('secondary');
  font-size: 1.2rem;
  padding: 0 1.8em;
}

.login-temp__statement2 {
  color: theme-color('black');
  font-size: 1rem;
  margin: 0 0 1.2em;
  padding: 0 2.1em;

  @media (min-width: $screen-lg) {
    padding-bottom: 1em;
  }
}

.login-temp__form {
  padding: 0 2em;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.login-temp__group {
  position: relative;
}

.login-temp__input {
  width: 100%;

  > label {
    transform: translate(14px, 14px) scale(1);
  }
}

.login-temp__show-pw {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 3px;
  top: 9px;

  &:focus {
    outline: none;
  }
}

.login-temp__icon {
  color: theme-color('background', 'medium');
}

.login-temp__icon--hide {
  display: none !important;
}

.login-temp__error {
  color: theme-color('red');
  display: block;
  font-size: 0.6rem;
  margin-bottom: 1.5em;
  text-align: left;
  visibility: hidden;
}

.login-temp__error--visible {
  visibility: visible;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.login-temp__error--no-margin {
  margin-bottom: 0;
}

.login-temp__privacy {
  font-size: 0.7rem;
  margin: 0;
  padding: 2em 0 0;

  > a {
    color: theme-color('primary');
    text-decoration: none;
  }
}

.login-temp__login {
  @extend .button;
  @extend .button--primary;

  width: 100%;

  &:disabled {
    @extend .button--disabled;
  }
}

.login-temp__illustration-cont {
  bottom: -4em;
  position: absolute;
  right: 0;
  transform: scaleX(-1);
  width: 20%;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.login-temp__illustration {
  transform: scaleX(-1);
  width: 100%;
}
