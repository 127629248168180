@import './Layout.scss';

.admin-brand {
  align-items: center;
  background-color: theme-color('primary2');
  display: flex;
  grid-area: brand;
  padding-left: 4em;
}

.admin-brand__logo {
  height: 2.5em;
}

.admin-header {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  color: theme-color('secondary');
  display: flex;
  grid-area: header;
  justify-content: space-between;
  padding-left: 4em;
  padding-right: 4em;
}

.admin-header__title {
  font-size: 2rem;
  margin: 0;
}

.admin-content {
  border-left: 1px solid theme-color('background', 'medium');
  border-right: 1px solid theme-color('background', 'medium');
  grid-area: content;
}

.admin-content__store-info {
  border-bottom: 1px solid theme-color('background', 'medium');
  overflow-x: auto;
  padding: 1em 2em;
}

.admin-content__store-hours {
  border-bottom: 1px solid theme-color('background', 'medium');
  padding: 0 2em 1em;
}

.admin-content__title {
  color: theme-color('primary2');
  font-size: 1.2rem;
  margin: 0;
  padding-bottom: 0.3em;
  text-align: left;
}

.admin-content__summary {
  align-items: center;
  display: flex;
}

.MuiExpansionPanelSummary-content {
  &.Mui-expanded {
    .admin-content__edit {
      transform: rotateZ(180deg);
    }
  }

  .admin-content__edit {
    transition: transform 250ms ease;
  }
}

.admin-content__info-cont {
  display: flex;
}

.admin-content__input {
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }
}

.admin-content__input--phone {
  width: 15em;
}

.admin-content__store-hours {
  overflow-x: auto;
}

.admin-content__table {
  border-spacing: 0;
  width: 100%;

  th,
  td {
    border: 1px solid theme-color('background', 'medium');
    border-right: none;
    font-weight: 700;
    padding: 0.5em;
    text-align: left;

    &:first-child {
      font-size: 0.9rem;
      text-transform: uppercase;
      text-align: center;
      width: 5em;
    }

    &:last-child {
      border-right: 1px solid theme-color('background', 'medium');
    }
  }

  th {
    border-bottom: none;
  }

  tbody {
    tr {
      &:nth-child(even) {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        td {
          border: none;
          padding: 0 0 0 0.8em;
        }
      }
    }
  }
}

.admin-content__checkbox {
  @extend .input-checkbox-cont;
  line-height: 1em;
}

.admin-footer {
  align-items: center;
  border-top: 1px solid theme-color('background', 'medium');
  display: flex;
  grid-area: footer;
  justify-content: space-between;
  padding: 0 8em;

  > p {
    > a {
      color: theme-color('primary');
      text-decoration: none;
    }
  }
}

.admin-footer__error-message {
  color: theme-color('red');
  font-size: 0.8rem;
  padding-right: 1em;
}

.admin-footer__divider {
  border-right: 1px solid theme-color('background', 'medium');
  height: 3em;
}

.admin-footer__sponsor {
  color: theme-color('primary2');
  font-size: 0.9rem;
  padding-left: 1em;
}

.admin-footer__submit {
  @extend .button;
  @extend .button--primary;
  margin: 0 0 0 1em;
  padding: 0.5em 1.5em;

  &:disabled {
    @extend .button--disabled;
  }
}
