@import '../../Styles/theme';
@import '../../Styles/forms';
@import '../../Styles/buttons';
@import '../../Styles/screens';

.store-info {
  overflow-x: auto;
  padding: 0.8em 2em;
}

.store-info__title {
  color: theme-color('primary2');
  font-size: 1.2rem;
  margin: 0;
  padding-bottom: 0.3em;
  text-align: left;
}

.store-info__row {
  display: flex;
  flex-direction: row;
}

.store-info__row--mt {
  margin-top: 1em;
}

.store-info__group {
  @extend .form-grp;

  width: 100%;

  @media (min-width: $screen-lg) {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    margin-top: 0;
    font-size: 1em;
  }
}

.store-info__group--google {
  margin-bottom: 0.25em;
  width: 50%;
}

.store-info__group--phone {
  width: 25%;
}

.store-info__group--mobile {
  width: 25%;
}

.store-info__group--whatsapp {
  display: flex;
  padding-top: 1.5em;
}

.store-info__input {
  width: 100%;
}

.store-info__footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.store-info__save {
  @extend .button;

  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
  color: theme-color('white');

  &:disabled {
    @extend .button--disabled;
  }
}

.Mui-disabled {
  cursor: not-allowed;
}
