@import '../../Styles/shapes';
@import '../../Styles/screens';

.cal-group-cell {
  > div {
    > div {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-indent: -9999px;

      @media (min-width: $screen-lg) {
        text-indent: initial;
      }
    }
  }
}

.cal-group-cell__resources {
  width: 100%;

  > div {
    > div {
      text-indent: initial;
    }
  }

  @media (min-width: $screen-lg) {
    display: none !important;
  }
}

.cal-group-cell__res-color {
  @extend .shape-circle;
  border: none;
  display: inline-block;
  height: 16px;
  margin-left: 1em;
  width: 16px;
}

.cal-group-cell__res-color--desktop {
  display: none;

  @media (min-width: $screen-lg) {
    display: initial;
  }
}
