@import '../../Styles/theme';
@import '../../Styles/screens';

.message--conversation--list .message--conversation--skeleton {
  div {
    padding: 10px 0px;
    opacity: 0.6;
    span {
      background: var(--app-color-light-shade);
      border-radius: 5px;
      height: 8px;
      width: 150px;
      margin: 5px 0;
      left: 0;
    }
    p {
      padding: 6px;
      width: 200px;
      background: var(--app-color-light-shade);
      box-shadow: none;
      opacity: 0.5;

      &:before {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .message--conversation--list .message--conversation--skeleton {
    div {
      &.sent {
        display: none;
      }
    }
  }
}
