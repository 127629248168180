@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

.cal-queue-mobile {
  height: calc(100% - 229px);
  position: absolute;
  right: 0;
  transition: width 0.4s ease-out;
  width: 10%;
  z-index: 3;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.cal-queue-mobile--expand {
  transition: 0.4s ease-out;
  width: 100%;
}

.cal-queue-mobile__toggler {
  align-items: center;
  background-color: theme-color('primary');
  border-radius: 15px 0 0 15px;
  display: flex;
  padding: 0 8px;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.cal-queue-mobile__show {
  align-items: center;
  background: none;
  border: none;
  color: theme-color('white');
  display: flex;
  height: 56px;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.cal-queue-mobile__title {
  color: theme-color('white');
  display: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 6em;
  text-transform: uppercase;
}

.cal-queue-mobile__title--visible {
  display: initial;
}

.cal-queue-mobile__icon {
  transition: 0.4s !important;
  transform: rotateZ(0deg) !important;
}

.cal-queue-mobile__icon--flip {
  transition: 0.4s !important;
  transform: rotateZ(180deg) !important;
}

.cal-queue-mobile__queue {
  @extend .custom-scrollbar;

  background-color: theme-color('white');
  pointer-events: none;
  height: 100%;
  overflow-x: hidden;
  padding-top: 0.8em;
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.cal-queue-mobile__queue--expanded {
  pointer-events: initial;
  opacity: 1;
  transition: opacity 0.4s ease-out;
  transition-delay: 0.3s;
}
