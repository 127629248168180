@import '../../Styles/theme';
@import '../../Styles/shapes';
@import '../../Styles/buttons';

.generic-footer {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.generic-footer__button-expansion {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.generic-footer__btn-container {
  display: flex;
  flex-direction: column;

  .generic-footer__btn {
    @extend .button;
    border: 0;
    text-transform: uppercase;
    margin: 1em 0;
  }
}

.generic-footer__expand {
  @extend .shape-circle;
  position: sticky;
  align-items: center;
  background: none;
  border: none;
  color: theme-color('white');
  display: flex;
  justify-content: center;
  height: auto;
  padding-right: 2em;
  padding-bottom: 2em;
  width: auto;
  margin-bottom: 1em;

  &:focus {
    outline: none;
  }
}

.generic-footer__expand--active {
  padding-bottom: 0;
  padding-top: 0;
  z-index: 15;
  position: fixed;
  bottom: 173px;
}

.generic-footer__expand-icon {
  font-size: 4em !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: 0.5s !important;
  transform: rotateZ(45deg);
  background-color: theme-color('button-secondary');
  border-radius: 1em;
}

.generic-footer__expand-icon--active {
  transition: 0.5s !important;
  transform: rotateZ(0deg);
  z-index: 15;
}
