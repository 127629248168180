@import '../../Styles/screens';

.cal-date-nav {
  align-items: center;
  display: flex;
  padding-left: 4px;
  position: relative;
}

.cal-date-nav__prev {
  padding: 4px !important;

  @media (min-width: $screen-md) {
    padding: 12px !important;
  }
}

.cal-date-nav__next {
  padding: 4px !important;

  @media (min-width: $screen-lg) {
    padding: 12px !important;
  }
}

.cal-date-nav__mobile-icon {
  padding: 4px !important;

  @media (min-width: $screen-lg) {
    display: none !important;
  }
}

.cal-date-nav__date-btn {
  display: none !important;

  @media (min-width: $screen-lg) {
    display: initial !important;
  }
}

.cal-date-nav__label-cont {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    flex-direction: row;
  }
}

.cal-date-nav__date-label {
  font-size: 0.8rem;
  font-weight: 700;
  padding-left: 8px;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.cal-date-nav__holiday-cont {
  align-items: center;
  display: flex;
}

.cal-date-nav__label-divider {
  display: none;
  padding-bottom: 4px;

  @media (min-width: $screen-lg) {
    display: initial;
  }
}

.cal-date-nav__holiday {
  font-size: 0.8rem;
  font-weight: 700;
  padding: 0 0 4px 8px;

  @media (min-width: $screen-lg) {
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 0;
  }
}
