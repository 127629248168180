@import './StellestStyles';

.stellest-form .stellest-appointment-form-section.patient-details-edit {
  border-top: none;
  .MuiFormControl-root {
    margin-bottom: 3em;
    margin-right: 3.8em;
    width: calc(25% - 3em);
    &.row-end {
      margin-right: 0;
    }
    .MuiFormLabel-root {
      padding: 0 0.1em;
      background: white;
    }
  }
}
