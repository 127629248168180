@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

.vac-item__summary {
  height: calc(var(--vh, 1vh) * 88);

  width: auto;

  @media (min-width: $screen-lg) {
    height: auto;
  }
}

.vac-item__main {
  width: auto;
  padding-top: 1em;
  border-bottom: 1px solid theme-color('mui');
}

.vac-item__recurrence {
  padding-top: 1em;
}

.vac-item__title {
  color: theme-color('primary2');
  font-size: 1rem;
  margin: 0;
  text-align: left;
  display: flex;
  justify-content: space-between;

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
  }
}

.vac-item__group {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    flex-direction: row;
    padding: 1em 1.5em;
  }
}

.vac-item__group--datetime {
  padding: 0;
  display: flex;
}

.vac-item__buttons {
  padding-top: 1.5em;
  display: flex;
  justify-content: flex-end;
}

.vac-item__ctrl {
  width: 100%;
}

.vac-item__input {
  width: 100%;

  @media (min-width: $screen-lg) {
    margin-bottom: 0 !important;

    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }
}

.vac-item__input--select {
  margin-bottom: 0;
  margin-left: 0 !important;
}

.vac-item__button {
  @extend .button;

  margin-bottom: 1em;
  padding-right: 2em;
  padding-left: 2em;

  &:disabled {
    @extend .button--disabled;
  }

  @media (min-width: $screen-lg) {
    margin: 0;
  }
}

.vac-item__submit {
  @extend .button;
  @extend .button--primary;

  margin-bottom: 1em;
  margin-left: 2em;
  padding-right: 2em;
  padding-left: 2em;

  &:disabled {
    @extend .button--disabled;
  }
}

.vac-item__action {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  margin-right: 1em;
  padding: 0;
  outline: none;

  &:focus {
    border-color: theme-color('primary');
  }
}

.vac-item-label {
  background-color: theme-color('white');
}

.vac-item__error {
  color: theme-color('red');
  display: block;
  font-size: 0.7rem;
  visibility: hidden;
}

.vac-item__error--visible {
  visibility: visible;
}
