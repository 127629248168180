@import './Layout.scss';
@import '../../Styles/screens';
@import '../../Styles/loading';

.cal {
  div[class^='MuiToolbar-root'] {
    background-color: theme-color('white');
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .cal-scheduler {
    > .MuiPaper-root {
      > div[class^='jss'],
      div[class^='Root-container'] {
        > .MuiGrid-root {
          overflow-y: initial;

          > div {
            > .MuiGrid-root {
              top: 57px;

              @media (min-width: $screen-lg) {
                top: 65px;
              }
            }
          }
        }
      }

      div[class^='AppointmentsContainer-container'] {
        div[class^='VerticalAppointment-content'] {
          height: 100%;
          padding: 0;
        }
      }
    }

    .scheduler-dayview__title {
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      display: flex;
      font-size: 1.2rem;
      font-weight: 700;
      justify-content: center;
      padding: 12px;
    }
  }

  .MuiDialog-container {
    height: 100vh;
  }
}

.cal-tablecell {
  border-right: 1px solid rgba(183, 183, 183, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  height: 48px;
  padding: 0;
  position: relative;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:last-child {
    border-right: none;
  }
}

.cal-tablecell--dragenter {
  background-color: rgba(0, 0, 0, 0.04);
}

.admin-calendar__footer {
  grid-area: footer;
  position: sticky;
  @media (min-width: $screen-lg) {
    display: none;
  }
}
