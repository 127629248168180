@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

.cal-newappt {
  @extend .custom-scrollbar;

  height: calc(var(--vh, 1vh) * 88);
  padding: 0 2em;
  width: auto;

  @media (min-width: $screen-lg) {
    height: auto;
    width: 650px;
  }
}

.cal-newappt__commands {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1em 0 1.5em;

  @media (min-width: $screen-lg) {
    padding: 1em 1.5em;
  }
}

.cal-newappt__title {
  color: theme-color('primary2');
  font-size: 1rem;
  margin: 0;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
  }
}

.cal-newappt__close {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
}

.cal-newappt__group {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    flex-direction: row;
    padding: 1em 1.5em;
  }
}

.cal-newappt__group--voucher {
  display: block;
  padding: 0.5em 0 0;

  @media (min-width: $screen-lg) {
    padding: 1.5em 1.5em 1em;
  }
}

.cal-newappt__group--right {
  justify-content: flex-end;
}

.cal-newappt__group--waitlist {
  padding: 0 0 0.5em;

  @media (min-width: $screen-lg) {
    padding: 0 1.5em;
  }
}

.cal-newappt__group--datetime {
  padding: 0;

  @media (min-width: $screen-lg) {
    padding: 0 1.5em;
  }
}

.cal-newappt__group--pt0 {
  padding-top: 0;
}

.cal-newappt__ctrl {
  width: 100%;
}

.cal-newappt__ctrl--time {
  @media (min-width: $screen-lg) {
    margin-top: 1em !important;
  }
}

.cal-newappt__input {
  color: theme-color('mui');
  margin-bottom: 1em !important;
  width: 100%;

  @media (min-width: $screen-lg) {
    margin-bottom: 0 !important;

    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }
}

.cal-newappt__input--voucher {
  @media (min-width: $screen-lg) {
    &:first-child {
      margin: 0;
    }
  }
}

.cal-newappt__voucher-cont {
  align-items: center;
  display: flex;

  @media (min-width: $screen-lg) {
    width: 50%;
  }
}

.cal-newappt__voucher-tooltip {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0 0 1em;

  &:focus {
    outline: none;
  }
}

.cal-newappt__voucher-icon {
  font-size: 1.2rem !important;
}

.cal-newappt__voucher {
  color: theme-color('mui');
  display: block;
  font-size: 0.7rem;
  font-weight: 400;
}

.cal-newappt__error {
  color: theme-color('red');
  display: none;
  font-size: 0.8rem;
  text-align: left;
}

.cal-newappt__error--visible {
  display: block;
}

.cal-newappt__input--notes {
  margin-bottom: 1em;

  @media (min-width: $screen-lg) {
    margin: 0 !important;
  }
}

#cal-newappt-appttype-label,
#cal-newappt-resource-label {
  background-color: theme-color('white');
}

.cal-newappt__input--select {
  margin-bottom: 0;
  margin-left: 0 !important;
}

.cal-newappt__book {
  @extend .button;
  @extend .button--primary;

  margin-bottom: 1em;
  padding-right: 2em;
  padding-left: 2em;

  &:disabled {
    @extend .button--disabled;
  }

  @media (min-width: $screen-lg) {
    margin: 0;
  }
}

.admin-content__has-editing {
  .admin-content_has-editing-notification {
    background-color: theme-color('red');
    border-radius: 50%;
    height: 15px;
    left: 7px;
    position: absolute;
    top: 13px;
    width: 15px;
  }
  .admin-content__has-editing-message {
    color: theme-color('red');
    margin: 6px 0;
  }
}

.input-timepicker {
  > .ant-picker-input {
    > input {
      border: 0;
      border-bottom: 1px solid red;
      border-radius: 0px;
    }
  }
}

.cal-newappt__sms {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (min-width: $screen-lg) {
    padding: 0 1.5em;
  }
}

.cal-newappt__sms-label {
  font-size: 0.9rem;
  text-transform: uppercase;

  @media (min-width: $screen-lg) {
    font-size: 1rem;
  }
}

.cal-newappt_sms-warning{
  color: red;
  font-size: 0.8rem;
  margin-top: -15px;
}
