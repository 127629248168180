@import '../../Styles/theme';
@import '../../Styles/buttons';

.loc-settings {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.loc-settings__heading {
  border-bottom: 1px solid theme-color('background');
  color: theme-color('primary');
  font-size: 2rem;
  margin: 0;
  padding-bottom: 1.2em;
}

.loc-settings__section {
  display: flex;
  flex-direction: column;
  margin: 2em 0 0;
}

.loc-settings__section--sms {
  margin-top: 1em;
}

.loc-settings__subheading {
  color: theme-color('primary');
  margin: 0 0 1em;
}

.loc-settings__checkbox {
  margin-left: 0.5em !important;
}

.loc-settings__footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.loc-settings__save {
  @extend .button;
  @extend .button--secondary;
}

.loc-settings__modal-button {
  @extend .button;
  @extend .button--primary;

  padding-right: 1em;
  padding-left: 1em;
}
