@import '../../Styles/checkboxes';

.res-hours {
  overflow-x: auto;
}

.res-hours__table {
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;

  th,
  td {
    border: 1px solid theme-color('background', 'medium');
    border-right: none;
    font-weight: 700;
    padding: 0.5em;
    text-align: left;

    &:first-child {
      font-size: 0.9rem;
      text-transform: uppercase;
      text-align: center;
      width: 5em;
    }

    &:last-child {
      border-right: 1px solid theme-color('background', 'medium');
    }
  }

  th {
    border-bottom: none;
  }

  tbody {
    tr {
      &:nth-child(even) {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        td {
          border: none;
          padding: 0 0 0 0.8em;
        }
      }
    }
  }
}

.res-hours__checkbox {
  @extend .input-checkbox-cont;
  line-height: 0.9em;
}

.res-hours__timeblock-cont {
  width: 100%;

  > div {
    &.Mui-disabled {
      > div {
        cursor: not-allowed;
      }
    }

    > div {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }
}

.res-hours__timeblock-error {
  color: theme-color('red');
  display: none;
  font-size: 0.6rem;
  text-align: left;
}

.res-hours__timeblock-error--visible {
  display: inline;
}
