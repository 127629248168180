@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/print';

.qr-code-poster {
  display: flex;
  flex-direction: column;
  padding: 2em 0 2em 2em;
  width: 680px;

  @media print {
    padding: 1em 2em;
  }
}

.qr-code-poster__heading {
  @extend .no-print;

  color: theme-color('primary');
  font-size: 2rem;
  margin: 0 0 1em 0;
}

.qr-code-poster__subheading {
  @extend .no-print;

  margin: 0 0 3em 0;
}

.qr-code-poster__print-cont {
  display: flex;
  justify-content: flex-end;
}

.qr-code-poster__print {
  @extend .button;
  @extend .no-print;

  background-color: theme-color('button-secondary');
  border: none;
  color: theme-color('white');
  width: 10em;
}
