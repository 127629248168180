@import '../../Styles/theme';
@import '../../Styles/buttons';

.cust-data {
  display: flex;
  flex-direction: column;
  padding: 2em 0 2em 2em;
  width: 815px;
}

.cust-data__heading {
  color: theme-color('primary');
  font-size: 2rem;
  margin: 0 0 0.3em 0;
}

.cust-data__subheading {
  margin: 0 0 3em 0;
}

.cust-data__download-cont {
  display: flex;
  justify-content: flex-end;
}

.cust-data__download {
  @extend .button;

  background-color: theme-color('button-secondary');
  border: none;
  color: theme-color('white');
  text-transform: uppercase;
  width: 15em;

  &:first-child {
    margin-right: 1em;
  }

  &:disabled {
    @extend .button--disabled;
  }
}
