@import '../../Styles/theme';

.qr-code-content {
  border: 1px solid theme-color('background', 'medium');
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding: 2em;
}

.qr-code-content__logo-cont {
  align-items: center;
  display: flex;
  justify-content: center;

  > div {
    width: 20%;
  }
}

.qr-code-content__logo {
  width: 100%;
}

.qr-code-content__logo-name {
  font-size: 4rem;
  padding-left: 0.3em;
}

.qr-code-content__body {
  align-items: center;
  border: 3px solid theme-color('black');
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
}

.qr-code-content__heading {
  font-size: 3rem;
  text-align: center;
}

.qr-code-content__subheading {
  background-color: theme-color('white');
  font-size: 3rem;
  margin: -70px 0 0;
  padding: 0 0.5em;
}

.qr-code-content__qr {
  height: 200px !important;
  margin: 2em 0;
  width: 200px !important;
}

.qr-code-content__verbiage {
  font-size: 1.1rem;
  margin: 0;
  text-align: center;

  @media print {
    font-size: 1rem;
  }
}

.qr-code-content__footer {
  text-align: center;
}

.qr-code-content__store-label {
  font-size: 2rem;
  margin: 0;
  padding-top: 1em;
}

.qr-code-content__store {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

.qr-code-content__divider {
  background-color: theme-color('background', 'medium');
  height: 12px;
  margin: 3em 0 1em;
  width: 100%;
}

.qr-code-content__sponsor {
  text-align: right;
}
