@import '../../Styles/theme';
@import '../../Styles/screens';

$messageListWidth: 380px;

.conversation__header {
  padding: 13px;
  box-shadow: 0 7px 6px -6px #979797;
  background-color: #ffffff;
  overflow: hidden;
  height: 4.6em;
  min-height: 4.6em;
  border-bottom: 1px solid theme-color('box-shadow', 'base');

  h1 {
    font-size: 1.2em;
    width: 100%;
    margin: 0;
  }

  p {
    margin: 0;
  }

  span {
    padding: 0;
    margin-top: 0;
  }

  .btn-back {
    display: none;
  }
}

.conversation__holder {
  z-index: 7;
  text-align: left;
  animation-name: slideIn;
  animation-fill-mode: forwards;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: calc(#{$messageListWidth + 1px});
  right: 0;
  bottom: 0;
  top: 0;
}

.conversation__list {
  overflow-y: scroll;
  padding-top: 2em;
  font-size: 0.8em;
  flex: 1;
  background-color: theme-color('background', 'light');

  div {
    margin: 5px 60px;
    margin-bottom: 10px;

    span {
      font-size: 0.8em;
      display: block;
      position: relative;
      padding: 2px;
    }

    p {
      display: block;
      padding: 10px;
      min-width: 250px;
      width: calc(100% - #{$messageListWidth});
      border-radius: 10px;
      margin: 1px 0;
      position: relative;
      box-shadow: 1px 6px 14px rgba(0, 0, 0, 0.07);
      line-height: 1.5rem;
      word-break: break-word;
      max-width: 400px;
    }



    &.sent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        right: 10px;
      }

      .twoWayConversation--message--footer {
        margin: 0;
        display: flex;
        flex-direction: row;
      }

      .twoWayConversation--message--status--error {
        color: theme-color('red');
        display: flex;
        flex-direction: row;
        padding-left: 1em;
        font-weight: bold;

        .MuiSvgIcon-root {
          font-size: 1rem;
        }
      }

      .message {
        position: relative;
        background-color: theme-color('primary', 'light-opacity');

        .system {
          background-color: theme-color('warning');
        }

        &:before {
          content: '';
          width: 0px;
          height: 0px;
          position: absolute;
          border-left: 12px solid theme-color('primary', 'light-opacity');
          border-right: 12px solid transparent;
          border-top: 12px solid theme-color('primary', 'light-opacity');
          border-bottom: 12px solid transparent;
          left: 10px;
          bottom: -10px;
          transform: rotate(0);
        }
      }

      .system {
        position: relative;
        background-color: theme-color('warning');

        &:before {
          content: '';
          width: 0px;
          height: 0px;
          position: absolute;
          border-left: 12px solid theme-color('warning');
          border-right: 12px solid transparent;
          border-top: 12px solid theme-color('warning');
          border-bottom: 12px solid transparent;
          left: 10px;
          bottom: -10px;
          transform: rotate(0);
        }
      }


    }

    &.receive {
      span {
        left: 10px;
        color: theme-color('black', 'base');
      }

      p {
        position: relative;
        background-color: theme-color('white', 'base');

        &::before {
          content: '';
          width: 0px;
          height: 0px;
          position: absolute;
          border-left: 6px solid theme-color('white', 'base');
          border-right: 6px solid transparent;
          border-top: 10px solid theme-color('white', 'base');
          border-bottom: 6px solid transparent;
          right: 10px;
          bottom: -8px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .conversation__holder--hide {
    display: none;
  }

  .conversation__header {
    .btn-back {
      display: inline-block;
      margin-top: -2px;
    }
  }

  .conversation__list {
    height: 67%;

    div {
      margin: 10px 25px;
    }
  }

  .conversation__holder {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    width: 100%;
    min-height: 500px;
  }

  .conversation__header {
    min-height: 1em;
    height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .row__start {
      display: flex;

      h1 {
        font-size: 1.25em;
        padding: 0;
        margin: 0;
        width: auto;
        color: theme-color('primary2', 'base');
        line-height: 0.6;
      }
    }

    p {
      margin: 0 0 0 1em;
    }
  }
}