@import './Layout.scss';

.admin-dashboard__menu {
  @extend .no-print;

  grid-area: menu;
}

.admin-dashboard__footer {
  @extend .no-print;
  grid-area: footer;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.admin-dashboard__header {
  @extend .no-print;

  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  grid-area: header;
  justify-content: flex-start;
  padding: 0 1.5em 0 2em;

  @media print {
    border: none;
  }
}

.admin-dashboard__arrow {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  .admin-dashboard__arrow-icon {
    transition: all 0.2s ease-in-out;
  }
  &.admin-dashboard__arrow--expanded {
    .admin-dashboard__arrow-icon {
      transform: rotate(180deg);
    }
  }
}

.admin-dashboard__title {
  @extend .no-print;

  margin: 0;
  margin-left: 2em;
}

.admin-dashboard__support {
  @extend .no-print;

  margin: 0;
  margin-left: auto;
}

.admin-dashboard__nav {
  @extend .no-print;

  border-right: 1px solid theme-color('background', 'medium');
  overflow-y: auto;
  grid-area: nav;
  grid-row-start: nav-start;
  grid-row-end: footer-end;
}

.admin-dashboard__content {
  grid-area: content;
  grid-row-start: content-start;
  grid-row-end: footer-end;
  overflow-x: auto;
  overflow-y: scroll;
  margin: 0;
}

.admin-dashboard__alert {
  @extend .no-print;

  background-color: theme-color('primary') !important;

  > div {
    &:first-child {
      color: theme-color('white');
    }

    &:nth-child(2) {
      color: theme-color('white');
    }

    &:last-child {
      color: theme-color('white');
    }
  }
}

.admin-dashboard__modal-btn {
  @extend .button;
  @extend .button--primary;
  @extend .no-print;

  padding-right: 1em;
  padding-left: 1em;
}

/* Helpdesk icon */
#freshworks-container {
  @media print {
    display: none !important;
  }
}
