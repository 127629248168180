@import '../../Styles/theme';
@import '../../Styles/buttons';

.cust-feedback {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.cust-feedback__heading {
  border-bottom: 1px solid theme-color('background');
  color: theme-color('primary');
  font-size: 2rem;
  margin: 0 0 1em 0;
  padding-bottom: 1.2em;
}

.cust-feedback__footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.cust-feedback__save {
  @extend .button;
  @extend .button--secondary;
}
