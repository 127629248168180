@import './Layout.scss';

.submenu__title {
  margin: 0 1em 0 0;
}

.submenu__cont {
  align-items: center;
  display: flex;
}

.submenu__legend {
  display: flex;
  list-style-type: none;
  margin: 0 1em 0 0;
  padding: 0;

  > li {
    align-items: center;
    display: flex;
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.submenu__autodel {
  border-left: 1px solid theme-color('background', 'medium');
  padding-left: 1em;
  text-align: left;
  width: 20em;
}

.page__messages {
  border: 1px solid theme-color('background', 'medium');
  border-top: none;
  border-spacing: 0;
  margin-bottom: 1em;
  padding-top: 1em;
  width: 100%;

  > thead {
    > tr {
      > th {
        border-bottom: 1px solid theme-color('background', 'medium');
        border-right: 1px solid theme-color('background', 'medium');
        font-size: 0.8rem;
        padding: 0.8em;
        text-align: left;
        text-transform: uppercase;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  > tbody {
    > tr {
      cursor: pointer;

      > td {
        border-right: 1px solid theme-color('background', 'medium');
        border-bottom: 1px solid theme-color('background', 'medium');
        padding: 0.8em;
        text-align: left;

        &:first-child {
          text-align: center;
        }

        &:last-child {
          border-right: none;
        }
      }

      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }
}

.notifications__status {
  width: 5%;
}

.notifications__dot {
  @extend .shape-circle;

  border: none;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.notifications__dot-label {
  font-size: 0.5rem;
  padding-left: 0.8em;
  text-transform: uppercase;

  @media (min-width: $screen-xl) {
    font-size: 0.8rem;
  }
}

.notifications__no-notifications {
  cursor: initial !important;

  > td {
    font-weight: 700;
  }
}

.notifications__dot--noshow {
  background-color: theme-color('warning');
}

.notifications__dot--feedback {
  background-color: theme-color('black');
}

.notifications__dot--cancel {
  background-color: theme-color('red');
}

.notifications__dot--unread {
  background-color: theme-color('success');
}

.notifications__dot--read {
  background-color: theme-color('background');
}

.notifications__message {
  width: 25%;
}

.notifications__datetime {
  width: 10%;
}

.notifications__author {
  width: 10%;
}

.notifications__email {
  width: 10%;
}

.notifications__feedback {
  width: 10%;
}

.notifications__feedback-cell {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.notifications__show-feedback {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.notifications__phone {
  width: 10%;
}

.notifications__resource {
  width: 10%;
}

.notifications__voucher {
  width: 5%;
}

.notifications__footer {
  grid-area: footer;
  position: sticky;
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.notifications-modal__header {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  position: relative;
}

.notifications-modal__title {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}

.notifications-modal__rating {
  color: theme-color('primary2', 'light');
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-right: 3em;
}

.notifications-modal__dismiss {
  background: none;
  border: none;
  color: theme-color('black');
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 8px;
}

.notifications-modal__content {
  width: 35em;
}

.notifications-modal__consumer {
  font-weight: 600;
  margin: 0;
  width: auto;
}

.notifications-modal__comments {
  font-weight: 600;
  margin: 1.5em 0 1em;
}

.notifications-modal__footer {
  padding: 0 1em 1em;
}

.notifications-modal__close {
  @extend .button;
  @extend .button--primary;
}
