@import '../../Styles/theme';
@import '../../Styles/buttons';

.store-holidays {
  display: flex;
  height: 100%;
  width: 100%;
}

.store-holidays__list-cont {
  border-right: 1px solid theme-color('background', 'medium');
  display: flex;
  flex-direction: column;
  width: 15em;
}

.store-holidays__list-title {
  border-bottom: 1px solid theme-color('background', 'medium');
  color: theme-color('secondary');
  margin: 0;
  padding: 1em 1em 1em 0.8em;
}

.store-holidays__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.store-holidays__item {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.store-holidays__item--selected {
  background-color: rgba(0, 172, 234, 0.12);
}

.store-holidays__item--disabled {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: not-allowed;
}

.store-holidays__item-text {
  color: theme-color('primary');
  font-size: 1.2rem;
  font-weight: 600;
}

.store-holidays__delete {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.store-holidays__icon {
  color: theme-color('background', 'medium');
  font-size: 1.5rem !important;
}

.store-holidays__add {
  align-items: center;
  background: none;
  border: 2px solid transparent;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  outline: none;
  padding: 0;
  margin-top: 1em;

  &:disabled {
    @extend .button--disabled;

    background: none;
    border: none;
  }

  &:focus {
    border-color: theme-color('primary');
  }
}

.store-holidays__form {
  padding: 0 2em;
  width: 45%;
}

.store-holidays__title-cont {
  border-bottom: 1px solid theme-color('background', 'medium');
  margin: 0.5em 0 1em;
  padding-bottom: 2em;
}

.store-holidays__form-title {
  color: theme-color('primary');
  display: inline;
  font-size: 2rem;
  margin: 0;
  padding-right: 1em;
}

.store-holidays__form-subtitle {
  font-size: 1.2rem;
  font-weight: 600;
}

.store-holidays__row {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.store-holidays__group {
  margin-bottom: 1em;
  width: 100%;
}

.store-holidays__group--spacer {
  visibility: hidden;
}

.store-holidays__input {
  width: 100%;
}

.store-holidays__spacer {
  font-weight: 600;
  padding: 0.5em 2em 0;
  text-transform: uppercase;
}

.store-holidays__spacer--spacer {
  visibility: hidden;
}

.store-holidays__spacer--md {
  padding: 0 2.5em;
}

.store-holidays__spacer--lg {
  padding: 0 10em;
}

.store-holidays__input--name {
  width: 35%;
}

.store-holidays__date {
  margin: 0 !important;
  width: 100%;

  /* Mui overrides */

  input {
    &:disabled {
      background-color: initial;
    }
  }
}

.store-holidays__error {
  color: theme-color('red');
  display: block;
  font-size: 0.7rem;
  visibility: hidden;
}

.store-holidays__error--visible {
  visibility: visible;
}

.store-holidays__holidays {
  margin-top: 0.5em !important;
  width: 100%;
}

.store-holidays__holidays--disabled {
  /* Mui overrides */

  .MuiSelect-select {
    cursor: not-allowed !important;
  }
}

#store-holidays-holidays {
  background-color: theme-color('white');
}

.store-holidays__footer {
  border-top: 1px solid theme-color('background', 'medium');
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  padding-top: 2.5em;
}

.store-holidays__button {
  @extend .button;

  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
  color: theme-color('white');
  margin-left: 2em;
  width: 8em;

  &:disabled {
    @extend .button--disabled;
  }
}

.store-holidays__button--cancel {
  background: none;
  color: theme-color('black');
  border: 1px solid theme-color('background', 'medium');
}
