@import './StellestStyles';

.stellest-form .stellest-appointment-form-section.medical-details {
  .MuiInputBase-multiline {
    .character-count {
      bottom: -25px;
      position: absolute;
      width: 200px;
    }
  }

  .left-col {
    width: 60%;
    .MuiFormControl-root {
      margin-bottom: 4em;
      width: calc(50% - 3.9em);

      margin-bottom: 3em;
      margin-right: 3.8em;
      &.row-end {
        margin-right: 0;
      }
      .MuiFormLabel-root {
        background: white;
        padding: 0 0.2em;
      }
    }
  }
  .right-col {
    width: 40%;
  }

  .right-col,
  .left-col {
    display: flex;
    flex-wrap: wrap;
  }

  .right-col {
    .prescription-table {
      width: 100%;

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      .label-text {
        display: block;
        background: white;
        color: grey;
        font-size: 12px;
        transform: translate(1em, -0.5em);
        margin: 0;
        padding: 0 0.5em;
        position: absolute;
        z-index: 1;
      }
      .label {
        display: flex;
        position: relative;
        width: 100%;
        span {
          align-items: center;
          color: theme-color('box-shadow');
          border: 1px solid $mui-input-border-color;
          border-right: none;
          display: flex;
          height: 3.8em;
          justify-content: flex-start;
          padding-left: 0.5em;
          text-transform: uppercase;
          width: calc(100% / 6);
          &:first-of-type {
            border-top-left-radius: 0.5em;
          }
          &:last-of-type {
            border-right: 1px solid theme-color('box-shadow');
            border-top-right-radius: 0.5em;
          }
        }
      }
      .fields {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .field-row {
          display: flex;
          width: 100%;
          &:last-of-type {
            span {
              &:first-of-type {
                border-bottom-left-radius: 0.5em;
              }
            }
            div {
              &:last-of-type {
                border-bottom-right-radius: 0.5em;
              }
            }
          }

          span {
            align-items: center;
            color: theme-color('box-shadow');
            display: flex;
            border: 1px solid $mui-input-border-color;
            border-top: 0;
            height: 3.8em;
            justify-content: center;
            width: calc(100% / 6);
          }
          .MuiFormControl-root {
            border-bottom: 1px solid $mui-input-border-color;
            border-right: 1px solid $mui-input-border-color;
            margin: 0;
            width: calc(100% / 6);
            .MuiInputBase-root {
              border-radius: 0;
              height: 60px;
              input[type='number']::-webkit-inner-spin-button {
                opacity: 1;
              }
              &.Mui-error {
                border: 1px solid red;
                border-radius: 0;
              }
              fieldset {
                border: 0;
                padding: 0;
                legend {
                  span {
                    border: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
