@import './Layout.scss';

.admin-settings__menu {
  @extend .no-print;

  grid-area: menu;
}

.admin-settings__title {
  @extend .no-print;

  margin: 0;
}

.admin-settings__header {
  @extend .no-print;

  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  grid-area: header;
  justify-content: space-between;
  padding: 0 1.5em 0 2em;

  @media print {
    border: none;
  }
}

.admin-settings__support {
  @extend .no-print;

  margin: 0;
}

.admin-settings .footer {
  @extend .no-print;
}

.admin-settings__nav {
  @extend .no-print;

  border-right: 1px solid theme-color('background', 'medium');
  grid-area: nav;
}

.admin-settings__content {
  grid-area: content;
}

.admin-settings__alert {
  @extend .no-print;

  background-color: theme-color('primary') !important;

  > div {
    &:first-child {
      color: theme-color('white');
    }

    &:nth-child(2) {
      color: theme-color('white');
    }

    &:last-child {
      color: theme-color('white');
    }
  }
}

.admin-settings__modal-btn {
  @extend .button;
  @extend .button--primary;
  @extend .no-print;

  padding-right: 1em;
  padding-left: 1em;
}

/* Helpdesk icon */
#freshworks-container {
  @media print {
    display: none !important;
  }
}
