@import '../../Styles/theme';
@import '../../Styles/screens';

.conversation__holder {
  &.conversation-new {
    button.btn-showall {
      padding: 0px 7px !important;
      margin: 0 0 0 16px;
      font-size: 10px !important;
    }

    .conversation__refresher {
      &--spinner {
        display: inline-flex;
        transform: scale(0.4);
        top: -12px;
        position: absolute;
        vertical-align: middle;
        margin: 5px 0;
        width: 25px;
        left: -22px;
      }
    }

    .conversation__container {
      overflow-y: visible;
      font-size: 0.9em;
      height: auto;
      padding-left: 14px;
      min-width: auto;
      padding-top: 0;
      padding-bottom: 0.2em;

      &.conversation-new__list {
        padding-left: 0;
        padding-top: 1.5em;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .conversation-new__header {
      &-close {
        margin-bottom: 5px;
      }

      .conversation-new__header-close--visible {
        display: none;
      }

      .conversation-new__content {
        display: flex;

        .exclamation-icon {
          font-size: 1.25em;
        }

        &-title {
          font-size: 0.8em;
          flex: 1;
          margin-right: 3em;
          margin-bottom: 2em;
        }

        &-tooltip {
          position: relative;

          &:hover {
            .conversation-new__content-tooltip-message {
              display: block;
            }
          }

          &-message {
            display: none;
            background: theme-color('white', 'base');
            border-radius: 6px;
            color: theme-color('black', 'base');
            padding: 0 1.5em;
            position: absolute;
            top: -6em;
            left: -35em;
            border: 1px solid theme-color('background', 'medium');
            height: 4em;
            line-height: 4;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
            width: 36em;

            &::after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              margin-left: -0.5em;
              bottom: -14px;
              right: 30px;
              box-sizing: border-box;
              border: 8px solid theme-color('background', 'medium');
              border-color: transparent transparent theme-color('white', 'base') theme-color('white', 'base');
              transform-origin: 0 0;
              transform: rotate(-45deg);
              box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
            }
          }
        }
      }

      .row__start {
        flex-wrap: wrap;

        h1 {
          margin-top: 15px;
          color: theme-color('primary2', 'base');
          margin-bottom: 7px;
        }
      }
    }

    height: 100%;
    background-color: theme-color('background', 'light');

    button {
      &.btn-clear {
        border: 1px solid theme-color('primary2', 'light');

        // @media only screen and (min-width: $screen-xl) and (max-width: 1400px) {
        //   margin-top: 0.5em;
        // }
      }
    }

    .conversation-new {
      position: relative;
      overflow: visible;

      &--notify--counts {
        display: inline-block;
        height: 20px;
        position: relative;
        top: 9px;
      }

      &__smsTo-input {
        width: auto;
        border: none;
        background: var(--app-color-light);
        padding-left: 0;

        border: 1px solid #ddd;
        border-radius: 0.3em;
        box-sizing: border-box;
        padding: 10px 15px;
        outline: none;
        margin-right: 5px;
        background: white;
        font-weight: 300;
        font-size: 0.8em;
        margin-bottom: 5px;

        &:active,
        &:focus {
          border-color: theme-color('mui-input-selected');
        }
      }

      &--status {
        height: 18px;
        position: relative;
        display: inline-block;
        margin: 12px 3px;
      }

      &__results {
        margin: 0px;
        background-color: theme-color('white', 'base');
        z-index: 10;
        box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
        position: absolute;
        right: 28%;
        min-width: 300px;
        width: min-content;
        height: 300px;
        font-size: 0.9em;

        &-header {
          border-bottom: 1px solid #979797;
          padding: 10px;
          padding-bottom: 5px;
          margin-right: 0;
          display: flex;
          font-size: 1.2em;
          justify-content: space-between;

          p {
            margin: 0;
            color: theme-color('primary2', 'light');
            width: 100%;
          }
        }

        &-content {
          overflow-y: scroll;
          height: 20em;
        }

        .row {
          padding: 10px;
          margin: 0;
          cursor: pointer;
          border-bottom: 1px solid theme-color('background', 'light');
          display: flex;
          justify-content: space-between;

          &:hover {
            color: var(--app-color-light-bright);
            background-color: var(--app-color-primary);
          }
        }
      }

      &__smsTo-holder {
        flex-wrap: wrap;
        display: flex;
      }

      &__smsTo-error-holder {
        margin-left: 20px;
      }

      &__smsTo {
        padding: 0.7em;
        color: theme-color('white', 'light');
        font-size: 0.8em;
        border-radius: 20px;
        position: relative;
        cursor: pointer;
        background-color: theme-color('primary2', 'light');
        display: flex;
        align-items: center;
        height: 1em;
        margin: 0.5em;

        &--remove {
          margin-left: 10px;
          font-size: 1.5em;
          padding-top: 3px;
        }
      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(10px, -5px) scale(0.65);
    }
  }

  .conversation-new__smsTo-input-phone {
    width: 10.8em;
    margin-right: 0.5em;

    &--code {
      height: 3em;
      font-size: 0.8em;

      p {
        margin: 0 !important;
      }
    }
  }

  .conversation-new__no-results {
    margin-top: 0.5rem;

    span {
      color: theme-color('error');
      font-weight: bold;
    }

    .conversation-new__add-contact {
      padding-left: 1rem;
      color: theme-color('primary2', 'light');
    }
  }
}

@media only screen and (max-width: $screen-xl) {
  .conversation__holder.conversation-new .conversation-new {
    &__content {
      flex-direction: column;

      p,
      .conversation-new__content-title {
        width: auto;
      }
    }

    &__header {
      .row__start {
        .conversation-new__smsTo-input-phone {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .conversation__holder {
    .conversation-new {
      &__content {
        flex-direction: column;

        &-tooltip-message {
          left: -16em !important;

          &::after {
            right: 56% !important;
          }
        }
      }

      &__header {
        flex-direction: column;

        h1 {
          margin-bottom: 0.8em;
        }

        .conversation-new__header-close--visible {
          display: block !important;
          width: auto !important;
        }

        &-close {
          align-items: center;
          justify-content: space-between;
        }
      }

      .btn-back {
        margin-top: 0.8em;
        transform: rotate(90deg);
        top: 45px;
        left: -55px;
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .conversation__holder.conversation-new {

    .conversation-new__header .conversation-new__content-title,
    .conversation-new__header .row__start h1 {
      margin: 0.5em 5px;
    }

    .conversation-new__smsTo-input,
    .conversation-new__smsTo-input-phone,
    .btn-clear {
      width: calc(50% - 10px);
      margin: 5px;
      margin-top: 5px !important;
    }

    input.conversation-new__smsTo-input {
      padding: 0.5rem 1rem;
    }

    .conversation-new__smsTo-input-phone input {
      padding: 0.5rem 0;
    }

    input.conversation-new__smsTo-input,
    .conversation-new__smsTo-input-phone input {
      font-size: 1rem;
    }

    .conversation-new__results {
      width: 100%;
      margin: 0;
      border-radius: 0;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 20;
    }

    button {
      &.btn-clear {
        margin-top: 0;
        height: 2.3em;
      }
    }

    &__list {
      flex-direction: column;

      &-container {
        display: inline-flex;
      }
    }
  }
}