@import '../../Styles/theme';

.pane--collapsed {
  .MuiPickersStaticWrapper-staticWrapperRoot {
    display: none;
  }
}

.cal-pane {
  height: 100%;
  position: relative;
}

.MuiPickersStaticWrapper-staticWrapperRoot {
  border-bottom: 1px solid theme-color('background');
  min-width: 305px !important;
}

.cal-pane__footer {
  padding: 0 2em;
  position: absolute;

  > p {
    font-size: 0.9rem;
    text-align: left;

    > a {
      color: theme-color('primary');
      text-decoration: none;
    }
  }
}
