@import '../../Styles/theme';
@import '../../Styles/shapes';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

.cal-queue {
  z-index: 2;

  .MuiIconButton-root {
    height: 30px;
    width: 30px;
  }
}

.cal-queue__header {
  align-items: center;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(183, 183, 183, 1);
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  height: 47px;
  justify-content: center;
}

.cal-queue__queue {
  @extend .custom-scrollbar;

  background-color: rgba(0, 0, 0, 0.04);
  border-left: 1px solid rgba(183, 183, 183, 1);
  overflow-y: scroll;
  height: calc(var(--vh, 1vh) * 84.8);
  padding-top: 1em;
}

.cal-queue__item {
  background-color: rgb(230, 230, 230);
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5em;

  @media (min-width: $screen-lg) {
    height: 75px;
  }

  &:last-child {
    margin-bottom: 1em;
  }
}

.cal-queue__time {
  align-items: center;
  border-right: 1px solid theme-color('background', 'medium');
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  justify-content: center;
  width: 12em;
}

.cal-queue__details {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  padding: 1em;
  width: 100%;

  > p {
    font-size: 0.9rem;
    margin: 0;
  }
}

.cal-queue__action-cont {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.8em;
  width: 100%;
}

.cal-queue__move {
  @extend .button;
  @extend .button--primary;

  font-size: 0.9rem;
  text-transform: uppercase;

  @media (min-width: $screen-lg) {
    display: none;
  }
}
