@import '../../Styles/screens';

.cal-day-viewer {
  display: none;

  @media (min-width: $screen-lg) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 64px;
  }
}

.cal-day-viewer__switcher {
  width: 10em;
}
