@import '../../Styles/theme';
@import '../../Styles/buttons';

.store-hours__timeblock-error {
  color: theme-color('red');
  display: none;
  font-size: 0.6rem;
  text-align: left;
}

.store-hours__timeblock-error--visible {
  display: inline;
}

.store-hours__timeblock-cont {
  width: 100%;

  > div {
    &.Mui-disabled {
      > div {
        cursor: not-allowed;
      }
    }

    > div {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }
}

.store-hours__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
}

.store-hours__save {
  @extend .button;

  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
  color: theme-color('white');

  &:disabled {
    @extend .button--disabled;
  }
}
