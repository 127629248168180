@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';

.login-norm {
  background-image: none;
  background-color: theme-color('white');
  height: 100%;
  position: relative;

  @media (min-width: $screen-lg) {
    background-size: 65%;
    background-image: url('../../Assets/Images/Login-Screen-Art.jpg');
    background-position: bottom right;
    background-repeat: no-repeat;
  }
}

.login-norm__title {
  border-bottom: 1px solid #979797;
  color: #084575;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 1em 1.3em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 2rem;
    margin-left: 0.5em;
    padding: 2em 0 1em 0;
    width: 75%;
  }
}

.login-norm__subtitle {
  color: #084575;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 2em 2em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;
    padding-left: 1.6em;
  }
}

.login-norm__form {
  padding: 0 2em;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.login-norm__group {
  position: relative;
}

.login-norm__input {
  width: 100%;

  > label {
    transform: translate(14px, 14px) scale(1);
  }
}

.login-norm__show-pw {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 3px;
  top: 9px;

  &:focus {
    outline: none;
  }
}

.login-norm__icon {
  color: theme-color('background', 'medium');
}

.login-norm__icon--hide {
  display: none !important;
}

.login-norm__error {
  color: theme-color('red');
  display: block;
  font-size: 0.6rem;
  margin-bottom: 1em;
  text-align: left;
  visibility: hidden;
}

.login-norm__error--visible {
  visibility: visible;
}

.login-norm__error--no-margin {
  margin-bottom: 0;
}

.login-norm__forgotpw {
  background: none;
  border: none;
  display: block;
  color: #00acea;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.5em 0 1.5em;
  outline: none;
}

.login-norm__login {
  @extend .button;
  @extend .button--primary;

  width: 100%;

  &:disabled {
    @extend .button--disabled;
  }
}

.login-norm__illustration-cont {
  bottom: -4em;
  position: absolute;
  right: 0;
  transform: scaleX(-1);
  width: 40%;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.login-norm__illustration {
  transform: scaleX(-1);
  width: 100%;
}
