@import '../../Styles/theme';
@import '../../Styles/buttons';

.MuiDialog-root {
  .MuiDialog-container {
    .messages__addpatient-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: theme-color('black', 'base');
    }
    .messages__addpatient-description {
      color: theme-color('black', 'base');
    }
    .messages__addpatient-label {
      font-weight: bold;
      color: theme-color('black', 'base');
      &.Mui-focused {
        color: theme-color('primary2', 'light');
      }
    }
    .messages_addpatient-sms-warning{
      color: red;
      font-size: 0.8rem;
      margin-top: -15px;
    }
    .messages__addpatient-btn {
      @extend .button;
      @extend .button--link;

      color: theme-color('black');
      text-transform: uppercase;

      &:hover {
        color: theme-color('primary2', 'light');
      }
    }
  }
}
