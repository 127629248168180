@import '../../Styles/theme';
@import '../../Styles/forms';
@import '../../Styles/buttons';
@import '../../Styles/shapes';

.res {
  padding: 0 2em 1em;
}

.res__content {
  display: flex;
  justify-content: space-between;
}

.res__table {
  border-spacing: 0 1em;

  th {
    text-align: left;

    > div {
      display: flex;
      justify-content: space-between;
      padding-right: 1.5em;
    }
  }

  td {
    padding-right: 1.5em;
    text-align: left;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding: 0;
    }
  }
}

.res__info-icon {
  @extend .shape-circle;
  background: none;
  background-color: theme-color('background', 'medium');
  border: none;
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  height: 25px;
  outline: none;
  width: 25px;
}

.res__info-tooltip {
  pointer-events: auto;
}

.res__statement {
  border: solid 2px theme-color('background', 'medium');
  background-color: theme-color('statement');
  padding: 1em;
  text-align: left;
  width: 15em;

  > p {
    margin: 0 0 1em 0;
  }
}

.res__form-grp {
  @extend .form-grp;

  &:not(:first-child) {
    margin-left: 1.5em;
  }
}

.res__form-input {
  width: 20em;
}

.res__res-color-cont {
  width: 100%;

  > div {
    &.Mui-disabled {
      > div {
        cursor: not-allowed;
      }
    }

    > div {
      align-items: center;
      height: 2.85em;
      display: flex;
      padding: 0 0 0 10px;
    }
  }
}

.res__res-color {
  @extend .shape-circle;
  border: none;
  display: inline-block;
}

.res__button {
  @extend .button;

  background-color: #fa716a;
  border-color: #fa716a;
  color: theme-color('white');
  padding: 0.68em 1em;

  &:disabled {
    @extend .button--disabled;
  }
}

.res__button--vac {
  @extend .button--secondary;

  background-color: theme-color('white');
  border-color: theme-color('background');
  color: theme-color('primary');
  text-transform: uppercase;
}

.res__modal-title {
  display: flex;
  justify-content: space-between;
}

.res__modal-h3 {
  margin: 0;
}

.res__res-label {
  font-size: 1.1em;
  font-weight: 600;
}

.res__res-name {
  font-size: 1.1em;
  padding-right: 2em;
}

.res__modal-close {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 0;
  outline: none;

  &:focus {
    border-color: theme-color('primary');
  }
}

.res__actions {
  align-items: center;
  display: flex;
}

.res__action {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  margin-right: 1em;
  padding: 0;
  outline: none;

  &:focus {
    border-color: theme-color('primary');
  }
}

.res__action--move-down {
  transform: rotate(180deg);
}

.res__add {
  align-items: center;
  background: none;
  border: 2px solid transparent;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  outline: none;
  padding-left: 0;
  margin-top: 1em;

  &:disabled {
    @extend .button--disabled;

    background: none;
    border: none;
  }

  &:focus {
    border-color: theme-color('primary');
  }
}

.res__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.res__save {
  @extend .button;

  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
  color: theme-color('white');

  &:disabled {
    @extend .button--disabled;
  }
}
