@import './StellestStyles';

.stellest-form .stellest-appointment-form-section.patient-details-view {
  .fields {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2em;
    .field {
      margin-right: 1em;
      .field-label {
        font-size: 0.8em;
        margin-bottom: 1em;
      }
      .field-value {
      }
    }
  }
  border-top: none;
  .MuiFormControl-root {
    margin-bottom: 3em;
    margin-right: 3.8em;
    width: calc(25% - 3em);
    &.row-end {
      margin-right: 0;
    }
    .MuiFormLabel-root {
      padding: 0 0.1em;
      background: white;
    }
  }
  h2 {
    margin-bottom: 1em;
  }
}
