@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';

.add-drawer-title-component {
  border-bottom: 1px solid theme-color('black');
  @media (min-width: $screen-lg) {
  }
  > section {
    padding: 1.5em 2em;
    display: grid;
    grid-template-columns: auto auto 2em;
    > .title {
      color: theme-color('secondary', 'base');
      font-size: 1rem;
      margin: 0;
      text-align: left;

      @media (min-width: $screen-lg) {
        font-size: 1.5rem;
      }
    }

    > .close-button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
      outline: none;
      justify-self: right;
    }
    > .required {
      justify-self: right;
      text-transform: uppercase;
      font-size: 0.8em;
      align-self: center;
      > .astrisk {
        margin-right: 0.25em;
        color: rgba(255, 0, 0, 1);
      }
    }
  }
}
