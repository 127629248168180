@import '../../Styles/theme';
@import '../../Styles/screens';

$messageListWidth: 380px;

.message--list {
  .dot {
    width: 0.8em;
    height: 0.8em;
    top: 2px;
  }
  .dot--blue {
    background-color: theme-color('primary', 'light');
  }

  .dot--green {
    background-color: theme-color('success', 'primary');
  }

  .dot--purple {
    background-color: theme-color('purple', 'primary');
  }
}
.card {
  font-size: 0.8em;
  display: block;
  padding: 15px 15px 15px 30px;
  border-bottom: 1px solid var(--app-color-dark-tint);
  border-bottom: 1px solid var(--app-color-light-shade);
  transition: all 0.24s;
  position: relative;
  line-height: 15px;
  word-break: break-word;
  height: 50px;

  .dot {
    position: absolute;
    top: 47%;
    left: 10px;
  }

  .row {
    span:first-child {
      font-weight: bold;
    }
    span:nth-child(2) {
      opacity: 0.5;
    }
  }
  p {
    margin: 5px 0;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &--optout {
    background-color: theme-color('background', 'base');
    p {
      white-space: normal;
      font-style: italic;
    }
  }
}

.card--holder {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid theme-color('background', 'base');
  border-top: none;
  .card--content {
    transition: left 0.24s;
    left: 0;
    position: relative;
  }
  &.active {
    background-color: theme-color('primary', 'light-opacity');
  }
  &:hover {
    background-color: theme-color('primary', 'light-opacity');
    .card--options {
      pointer-events: revert;
      .card--options--flag--holder {
        right: -5px;
        transition-delay: 0.04s;
      }
      .card--options--trash {
        right: -5px;
      }
    }
    .card--content {
      left: -10px;
    }
    .card {
      cursor: pointer;
      background-color: var(--app-color-primary-shade);
      color: var(--app-color-light-bright);
    }
  }
  .card.active {
    cursor: pointer;
    background-color: var(--app-color-primary-shade);
    color: var(--app-color-light-bright);
  }

  .react-icon {
    font-size: 1.5em;
    color: theme-color('background', 'medium');
  }
}
.card--options {
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  height: 80px;
  pointer-events: none;
  right: 1em;
  .card--option {
    padding: 1em;
    padding-top: 30px;
    color: theme-color('background', 'medium');
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: theme-color('primary', 'base');
      .react-icon {
        color: theme-color('white', 'base');
      }
    }
  }

  &--flag--holder {
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    right: -$messageListWidth - 50px;
    transition: right 0.24s;
    &:hover .card--options--flags {
      display: flex;
      top: 80px;
    }
  }
  .card--options--flag {
    position: relative;
    z-index: 1;
  }
  .card--options--flags {
    display: none;
    flex-direction: column;
    position: absolute;
    left: 0;
    line-height: 0;
    transition: all 0.24s;
    top: 0px;
    z-index: 2;

    .dot {
      top: 0;
      left: 0;
      width: 0.9em;
      height: 0.9em;
    }

    .dot--clear {
      font-size: 1.2em;
      color: theme-color('background', 'medium') !important;
      border: none !important;
    }

    div {
      padding: 1.2em;
      border: 1px solid theme-color('clear', 'base');
      background-color: theme-color('white', 'base');
      box-shadow: 1px 0px 19px rgba(27, 55, 100, 0.07);

      &:hover {
        background-color: theme-color('primary', 'base');
      }
    }
  }

  .card--options--trash {
    transition: right 0.24s;
    right: -$messageListWidth - 50px;
  }

  .card--options--trash-confirm {
    display: none;
    &.active {
      display: flex;
      position: absolute;
      right: 0.5em;
      background-color: theme-color('white', 'base');
      top: 6.3em;
      width: 10em;
      font-size: 0.8em;
      color: theme-color('black', 'base');
      flex-direction: column;
      align-items: center;
      box-shadow: -5px 4px 9px 0px theme-color('mui', 'border');
      z-index: 1;

      .confirm,
      .cancel {
        height: 2em;
        width: 100%;
        text-align: center;
        padding-top: 10px;

        &:hover {
          background-color: theme-color('primary', 'light-opacity');
          font-weight: 500;
        }
      }
      .cancel {
        border-top: 1px solid theme-color('background', 'base');
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .card--holder {
    .card--options.mobile {
      display: none;
    }
    .back {
      display: none;
    }
    &.is-mobile-active {
      background-color: theme-color('primary', 'light-opacity');
      .back {
        position: absolute;
        right: 8.5em;
        top: 40%;
        display: block;
      }
      .card--options.mobile {
        background-color: theme-color('white', 'base');
        width: 8em;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        right: 0;
        pointer-events: all;

        .mobile {
          &__flag,
          &__delete {
            width: 100%;
            // background-color: theme-color('primary', 'base');
            color: white;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 0.9em;

            .active {
              margin: 0 0.5em;
            }
            &-options {
              position: absolute;
              top: 5em;
              background-color: theme-color('white', 'base');
              border: 1px solid theme-color('mui', 'border');
              width: 100%;

              div {
                border-bottom: 1px solid theme-color('mui', 'border');
                height: 3em;
                padding-left: 1em;
                font-size: 0.8em;
                display: flex;
                align-items: center;

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
          &__flag {
            background-color: theme-color('primary', 'base');
          }
          &__delete {
            background-color: theme-color('red', 'secondary');
          }
        }
      }
    }
  }
} ;
