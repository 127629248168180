@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';

.cal-appttooltip {
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
  }
}

.cal-appttooltip__header {
  display: flex;
  justify-content: flex-end;
}

.cal-appttooltip__command {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.15em 0.5em 1em 0.5em;

  @media (min-width: $screen-lg) {
    margin: 0.15em 1em 0.5em 1em;
  }
}

.cal-appttooltip__command--close {
  margin-top: 0.4em !important;
}

.cal-appttooltip__body {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 1.5em 2em;
}

.cal-appttooltip__body-right {
  padding-left: 1em;

  h3 {
    color: theme-color('background', 'medium');
    font-size: 1.5rem;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.cal-appttooltip__appt-color {
  @extend .shape-circle;
  background-color: theme-color('background');
  border: theme-color('background');
}

.cal-appttooltip__time {
  display: flex;
  margin-bottom: 0;
  padding-left: 0.3em;
  width: 100%;

  > svg {
    margin-right: 0.7em;
  }
}

.cal-appttooltip__notes {
  display: flex;
  margin-top: 1em;
  overflow-wrap: anywhere;
  padding: 0 1em 0 0.3em;
  width: 100%;

  > svg {
    margin-right: 0.7em;
  }

  > p {
    margin: 0;
  }
}

.cal-appttooltip__voucher-cont {
  display: flex;
  margin-top: 1em;
  padding: 0 1em 0 0.3em;
}

.cal-appttooltip__voucher-icon {
  margin-right: 0.7em;
}

.cal-appttooltip__voucher-details {
  display: flex;
  flex-direction: column;
}

.cal-appttooltip__voucher-code {
  font-weight: 600;
  margin: 0;
}

.cal-appttooltip__voucher-desc {
  margin: 0;
}

.cal-appttooltip__voucher-expire {
  font-weight: 600;
  margin: 0;
}

.cal-appttooltip__confirmation-parent {
  width: 90%;
  padding-top: 5%;
}

.cal-appttooltip__confirmation-button {
  color: theme-color('white');
  cursor: pointer;
  background-color: theme-color('button-secondary');
  border-width: 0px;
  border-radius: 0.3em;
  font-size: 1.2rem;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.cal-appttooltip__confirmation-content-headline {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 7%;
  }
  span {
    color: theme-color('button-secondary');
  }
}

.cal-appttooltip__confirmation-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 10%;
  padding-bottom: 10%;

  span {
    padding-left: 5%;
  }
}
