@import '../../Styles/theme';
@import '../../Styles/screens';

.chat {
  height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4em 5em auto;
  grid-template-areas: 'menu' 'submenu' 'page';

  .menu {
    grid-area: menu;
    overflow: hidden;
  }

  .submenu {
    align-items: center;
    border-bottom: 1px solid theme-color('background', 'medium');
    display: flex;
    grid-area: submenu;
    justify-content: space-between;
    padding: 0 1em 0 2em;
  }

  .submenu__title {
    margin: 0 1em 0 0;
  }

  .submenu__cont {
    align-items: center;
    display: flex;
  }

  .submenu__legend {
    display: flex;
    list-style-type: none;
    margin: 0 1em 0 0;
    padding: 0;

    > li {
      align-items: center;
      display: flex;
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .submenu__today {
    vertical-align: middle;
    margin-top: 0.25em;
    font-weight: bold;
  }

  .submenu__date {
    vertical-align: middle;
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 0.75em;
  }
}

.page-two-way__message {
  display: flex;
  grid-area: page;
  position: relative;

  .MuiSvgIcon-fontSizeLarge.page-two-way__message-add {
    display: none;
  }
}

.page-two-way__footer {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.page-two-way__footer {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.page-two-way__footer {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

@media only screen and (max-width: $screen-lg) {
  .chat {
    grid-template-rows: 4em 5em 1fr 4em;
    grid-template-areas:
      'menu'
      'submenu'
      'page'
      'footer';
    .submenu {
      font-size: 0.9em;
    }
  }
  .page-two-way__message {
    grid-row-start: page-start;
    grid-row-end: footer-end;

    .MuiSvgIcon-fontSizeLarge.page-two-way__message-add {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 0.3em;
      margin-right: 0.5em;
      color: theme-color('button-secondary', 'base');
      z-index: 7;
      display: block;
    }
  }

  .page-two-way__footer {
    grid-area: footer;
    position: fixed;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    .generic-footer__expand {
      pointer-events: all;
    }
  }
}

@media only screen and (max-width: $screen-sm) {
  .chat {
    .submenu {
      font-size: 0.75em;
    }
  }
}
