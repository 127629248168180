@import '../../Styles/theme';
@import '../../Styles/screens';

$messageListWidth: 380px;

.message-list__header {
  height: calc(4.6rem - 2px);
  overflow: hidden;
  padding: 14px;
  box-shadow: 0 7px 6px -6px theme-color('background', 'medium');
  border-bottom: 1px solid theme-color('box-shadow', 'base');
  .btn-row button {
    background-color: theme-color('primary', 'base');
    color: theme-color('white', 'base');
  }
}

.message-list {
  max-width: $messageListWidth;
  width: $messageListWidth;
  text-align: left;
  padding: 0;
  border-right: 1px solid theme-color('background', 'medium');
  animation-duration: var(--app-animation-speed);
  animation-name: slideIn;
  animation-fill-mode: forwards;
  position: relative;

  .dot--clear {
    border: 1px solid theme-color('gray', 'light') !important;
  }

  .message-list__scroll {
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: calc(4.6rem + 28px);
    bottom: 0;
    left: 0;
    right: 0;

    &--loadmore {
      height: 70px;
      padding: 20px;
      text-align: center;
      button {
        margin: 0 auto;
        border: 1px solid theme-color('primary', 'base');
        color: theme-color('primary', 'base');
      }
    }
  }
  .message-list__input--search {
    padding: 20px;
    position: relative;
    border-bottom: 1px solid var(--app-color-light-shade);
    .btn-search {
      position: absolute;
      top: 25px;
      left: 25px;
      display: inline-block;

      &--close {
        right: 25px;
        top: 25px;
        cursor: pointer;
        position: absolute;
        &:hover {
          color: var(--app-color-danger);
        }
      }
    }
    input {
      margin: 0px;
      padding: 5px 10px;
      padding-left: 26px;
      border-radius: 20px;
      border: 1px solid var(--app-color-light-shade);
    }
    .txt--alert {
      margin-left: 25px;
    }
  }
  .message-list__refresher {
    font-size: 0.8em;
    padding: 0 30px 0;
    border-bottom: 1px solid var(--app-color-light-shade);
    height: 40px;

    &--buttons {
      width: 30px;
      display: inline-block;
    }
    p {
      margin: 0;
      display: inline-block;
      padding: 0;
      line-height: 40px;
      padding-right: 10px;
    }
    .btn-refresh {
      padding-left: 0 !important;
      margin-left: 0 !important;
      width: 25px;
      min-width: 10px !important;
      padding-right: 10px !important;
      position: relative;
      top: -1px;
    }
    &--spinner {
      display: inline-flex;
      transform: scale(0.5);
      top: -1px;
      position: relative;
      vertical-align: middle;
      margin: 5px 0;
      width: 25px;
      left: -2px;
    }
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    position: relative;
    top: -2px;
    left: -3px;

    &--blue {
      background-color: theme-color('primary', 'light');
    }
    &--green {
      background-color: theme-color('success', 'secondary');
    }
    &--purple {
      background-color: theme-color('purple', 'primary');
    }
    &--clear {
      border: 1px solid var(--app-color-light-shade);
      &--mobile {
        width: 15px;
        height: 15px;
        margin-right: -6px;
        left: -6px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .btn-row {
    margin-bottom: 20px;
    margin-left: 16px;
    button {
      box-shadow: none;
    }
    button:first-child {
      margin-right: 10px;
    }
  }

  ul {
    font-size: 0.75em;
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    padding: 0;
    li {
      cursor: pointer;
      border-bottom: 2px solid transparent;
      display: flex;
      align-items: flex-end;

      &:hover,
      &.active {
        border-bottom: 2px solid theme-color('orange');
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .message-list {
    max-width: 100vw;
    width: 100vw;
    border: none;

    &.message-list--hide {
      display: none;
    }

    .message-list__scroll {
      padding-bottom: 10rem;
    }
  }
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
  }

  /* Modal Content */
  .modal-content {
    position: fixed;
    bottom: 0;
    background-color: #fefefe;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
  }

  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  .modal-body {
    padding: 2px 16px;
  }

  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  /* Add Animation */
  @-webkit-keyframes slideIn {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
